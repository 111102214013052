import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link, Modal, Autocomplete, TextField } from '@mui/material';

import { getById, getDataWithSets } from '../../../../services/configurationService';
import { useToaster } from '../../../../providers/ToasterProvider';
import { deleteAllBySet, setWiseUpload, getPendingVerificationQuestionNumber, bulkUpload, deleteAll } from '../../../../services/questionService';
import { ContentUploadErrorsModal } from '../ContentUploadErrorsModal/ContentUploadErrorsModal'

import RadiusButton from '../../../../components/RadiusButton';

import DELETE from '../../../../assets/images/action-delete.svg';

export const UploadByBulk = ({ drive, course, configurationId }) => {

    const params = useParams();
    const projectName = params?.projectName;
    const navigate = useNavigate();
    const { addNotification } = useToaster();

    const [configuration, setConfiguration] = useState(null);
    const [sets, setSets] = useState([]);
    const [numberOfQuestions, setNumberOfQuestions] = useState({
        'E': 0,
        'M': 0,
        'H': 0,
    });
    const [fileDetails, setFileDetails] = useState({
        file: null,
    });
    const [dataUploaded, setDataUploaded] = useState(false);
    const [errorModal, setErrorModal] = useState({
        isVisible: false,
        errors: []
    });
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        if (configurationId) {
            const fetchConfiguration = async () => {
                try {
                    const res = await getDataWithSets(configurationId);
                    if (res.code === 'OK') {
                        const configuration = res.data.configuration;
                        const sets = res.data.sets;
                        setConfiguration(configuration);
                        const easyQuestionsCount = configuration.numberOfEasyQuestions;
                        const mediumQuestionsCount = configuration.numberOfMediumQuestions;
                        const hardQuestionsCount = configuration.numberOfHardQuestions;
                        setNumberOfQuestions({
                            'E': easyQuestionsCount * configuration.numberOfSets,
                            'M': mediumQuestionsCount * configuration.numberOfSets,
                            'H': hardQuestionsCount * configuration.numberOfSets
                        })
                        setSets(
                            res.data.sets.map((set) => {
                                set.languages = set.languages.map((lan) => {
                                    // lan.previewNo = '';
                                    // lan.inputNo = '';
                                    // lan.filteredOptions = Array.from({ length: lan.count + 1 }, (_, i) => i ? i.toString() : '');
                                    return lan
                                })
                                set.hasUploadedData = !!set.languages.find((lan) => lan.count > 0);
                                return set;
                            })
                        )
                    }
                } catch (error) {
                    console.log('There is an error while getting configuration details', error);
                }
            }
            fetchConfiguration();
        }
    }, [configurationId, dataUploaded]);

    const handleFileDrop = (e, set, language) => {
        const inputFile = e.target.files?.[0];
        if (inputFile && inputFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            if (fileDetails?.set && fileDetails?.file && fileDetails?.language) {
                document.getElementById(`file_${fileDetails.set}_${fileDetails.language}`).value = '';
            }
            setFileDetails({
                file: inputFile,
                set: set,
                language: language
            });
        }
    }
    const handleRemoveFile = () => {
        document.getElementById('file').value = '';
        setFileDetails({
            file: null,
        });
    }
    const handlePreviewQuestion = async (setNumber, language) => {
        if (!language || !setNumber) return;
        try {
            const body = {
                configurationId: configurationId,
                set: setNumber,
                language: language
            }
            const res = await getPendingVerificationQuestionNumber(body);
            if (res.code === 'OK') {
                navigate(`/${projectName}/upload/content-preview`, {
                    state: {
                        configuration: configuration,
                        language: language,
                        setNumber: setNumber,
                        drive: drive,
                        course: course,
                        questionNo: res.data.questionNo,
                    }
                });
            }
        } catch (error) {
            console.log(error);
            addNotification('Unexpected error occured.', 'danger')
        }
        // const set = sets.find((set) => set.set === setNumber);
        // if (set) {
        //     const previewLanguage = set.languages.find((lan) => lan.language === language);
        //     const questionNo = Number(previewLanguage?.previewNo);
        //     if (questionNo && !isNaN(questionNo)) {
        //         navigate(`/${projectName}/upload/content-preview`, {
        //             state: {
        //                 configuration: configuration,
        //                 language: language,
        //                 setNumber: setNumber,
        //                 drive: drive,
        //                 course: course,
        //                 questionNo: questionNo,
        //             }
        //         });
        //     } else {
        //         addNotification('Please select question number.')
        //     }
        // }
    }
    const handleDeleteAll = () => {
        if (!drive?.id || !course?.id) return;
        setDeleteModal(true);
    }
    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
    }
    const handleDeleteAllConfirm = async () => {
        if (!drive?.id || !course?.id) return;
        try {
            const body = {
                driveId: drive.id,
                courseId: course.id
            }
            const res = await deleteAll(body);
            if (res.code === 'OK') {
                setDataUploaded(!dataUploaded);
                addNotification('content deleted successfully.', 'success');
                setDeleteModal(false);
            }
        } catch (error) {
            console.log('There is an error while deleting questions', error);
        }
    }
    const handleContentUpload = async () => {
        if (fileDetails?.file && configuration.id) {
            try {
                const body = new FormData();
                body.append('configurationId', configuration.id);
                body.append('file', fileDetails.file);
                const res = await bulkUpload(body);
                if (res.code === 'E_BAD_REQUEST') {
                    addNotification('Invalid data in file data', 'danger');
                    setErrorModal({
                        ...errorModal,
                        isVisible: true,
                        errors: res.data.errors,
                    })
                } else {
                    setDataUploaded(!dataUploaded);
                    const ele = document.getElementById('file');
                    if (ele) {
                        ele.value = '';
                    }
                    setFileDetails({
                        file: null
                    })
                    addNotification('Content uploaded successfully.', 'success');
                }
            } catch (error) {
                console.log('There is an error while uploading file set wise', error);
                addNotification('Content does not uploaded.', 'danger');
            }
        }
    }
    // const setPreviewQuestionNo = (value, setNumber, language) => {
    //     if (value && setNumber && language) {
    //         setSets(
    //             sets.map((set) => {
    //                 if (set.set === setNumber) {
    //                     set.languages = set.languages.map((lan) => {
    //                         if (lan.language === language) {
    //                             lan.previewNo = value;
    //                             lan.inputNo = value;
    //                         }
    //                         return lan;
    //                     })
    //                 }
    //                 return set;
    //             })
    //         )
    //     }
    // }
    // const setPreviewQuestionInput = (value, setNumber, language) => {
    //     if (setNumber && language) {
    //         setSets(
    //             sets.map((set) => {
    //                 if (set.set === setNumber) {
    //                     set.languages = set.languages.map((lan) => {
    //                         if (lan.language === language) {
    //                             lan.inputNo = value;
    //                             lan.previewNo = '';
    //                         }
    //                         return lan;
    //                     })
    //                 }
    //                 return set;
    //             })
    //         )
    //     }
    // }


    return (
        <Fragment>
            <Box sx={{
                p: 3,
            }}>
                {configuration && <>
                    <Box sx={{ mb: 1.5 }}>
                        Number of sets are:
                        <Box component='span' sx={{ textTransform: 'capitalize', ml: 1.5, color: 'primary.main', fontSize: 18 }}>{configuration.numberOfSets}</Box>

                    </Box>
                    <Box sx={{ mb: 1.5 }}>
                        Selected languages are:
                        <Box component='span' sx={{ textTransform: 'capitalize', ml: 1.5, color: 'primary.main', fontSize: 18 }}>{configuration.languages.join(', ')}</Box>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        Content should have
                        <Box component='span' sx={{ mx: 0.75, fontSize: 18, color: 'primary.main' }}>{numberOfQuestions.E}</Box>
                        Easy question(s),
                        <Box component='span' sx={{ mx: 0.75, fontSize: 18, color: 'primary.main' }}>{numberOfQuestions.M}</Box>
                        Medium question(s) and
                        <Box component='span' sx={{ mx: 0.75, fontSize: 18, color: 'primary.main' }}>{numberOfQuestions.H}</Box>
                        Hard question(s) per language.
                    </Box>
                </>
                }
                {<Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    height: 24,
                    mb: 2,
                }}>
                    <RadiusButton
                        sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            cursor: 'pointer',
                        }}
                        component='label'
                        variant='contained'
                        color='primary'
                        htmlFor='file'
                    >Select file</RadiusButton>
                    <input
                        style={{ display: 'none' }}
                        type='file'
                        id='file'
                        onChange={(e) => handleFileDrop(e)}
                        accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    />
                    {
                        fileDetails?.file && fileDetails?.file?.name
                        && (
                            <>
                                <Box component='span'>{fileDetails.file.name}</Box>
                                <img
                                    style={{ cursor: 'pointer' }}
                                    src={DELETE}
                                    onClick={handleRemoveFile}
                                    alt=''
                                />
                            </>
                        )
                    }
                </Box>}
                {
                    fileDetails?.file && <RadiusButton
                        variant='contained'
                        color='secondary'
                        onClick={handleContentUpload}
                    >
                        Upload
                    </RadiusButton>
                }
            </Box>
            <TableContainer sx={{
                p: 2,
                maxHeight: 'inherit',
                maxWidth: 'inherit',
                borderRadius: 1
            }}>
                <Table stickyHeader aria-label="table" sx={{ border: 'none' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align='left'
                                style={{ width: 140 }}
                                sx={{ border: 'none', p: 0 }}
                            ></TableCell>
                            <TableCell
                                align='left'
                                style={{ minWidth: 320 }}
                                sx={{ border: 'none', p: 0 }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sets.map((setDetails, index) => {
                            return setDetails.hasUploadedData && <TableRow key={index}>
                                <TableCell sx={{ border: 'none', verticalAlign: 'top' }}>
                                    <Typography component='span' variant='h6' sx={{
                                        fontSize: 18,
                                        fontWeight: 500,
                                        color: 'primary.main'
                                    }}>Set {setDetails.set}</Typography>
                                </TableCell>
                                <TableCell sx={{ border: 'none', py: 1 }}>
                                    {setDetails?.languages?.map((lan, lanIndex) => {
                                        return <Box key={lanIndex} sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            gap: 2,
                                            mb: 1,
                                        }}>
                                            <Typography sx={{
                                                textTransform: 'capitalize',
                                                fontWeight: 500,
                                                fontSize: 16,
                                                py: 1,
                                                width: 140
                                            }}>{lan.language}</Typography>
                                            <Box sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                gap: 1.5,
                                                py: 1.5,
                                                px: 2.5,
                                                backgroundColor: 'grey.100',
                                                borderRadius: 2,
                                            }}>
                                                {(lan.count > 0) && (
                                                    <Typography
                                                        variant='subtitle1'
                                                        component='h6'
                                                        // onClick={() => handleDeleteAll(setDetails.set, lan.language)}
                                                    >Verified ({lan?.verifiedCount} / {configuration?.questionsPerSet})</Typography>
                                                )}
                                                <Box sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    gap: 1,
                                                    alignItems: 'center',
                                                }}>
                                                    {lan?.count > 0 && <>
                                                        {/* <Autocomplete
                                                            value={lan?.previewNo}
                                                            onChange={(event, newValue) => {
                                                                setPreviewQuestionNo(newValue, setDetails.set, lan.language);
                                                            }}
                                                            inputValue={lan?.inputNo}
                                                            onInputChange={(event, newInputValue) => {
                                                                setPreviewQuestionInput(newInputValue, setDetails.set, lan.language);
                                                            }}
                                                            id="lan"
                                                            options={lan.filteredOptions}
                                                            sx={{ width: 180 }}
                                                            renderInput={(params) => <TextField {...params} label="Question No" />}
                                                        /> */}
                                                        <RadiusButton
                                                            variant='contained'
                                                            color='primary'
                                                            onClick={() => handlePreviewQuestion(setDetails.set, lan.language)}
                                                        >Preview</RadiusButton>
                                                    </>}
                                                </Box>
                                            </Box>
                                        </Box>
                                    })}
                                </TableCell>
                            </TableRow>
                        })}
                        {sets?.[0]?.hasUploadedData && <TableRow>
                            <TableCell sx={{ border: 'none' }}></TableCell>
                            <TableCell sx={{ border: 'none' }}>
                                <RadiusButton variant='contained' color='secondary' onClick={handleDeleteAll}>
                                    Delete all
                                </RadiusButton>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={deleteModal}
                onClose={handleCloseDeleteModal}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.base',
                    boxShadow: 24,
                    borderRadius: 2,
                    px: 3,
                    py: 6
                }}>
                    <Typography variant="h6" component="h6" sx={{
                        mb: 5,
                        textAlign: 'center'
                    }}>
                        Are you sure you want to delete all the content for
                        <Box component='span' sx={{ color: 'primary.main' }}> Set {deleteModal.set} </Box>
                        in
                        <Box component='span' sx={{ color: 'primary.main', textTransform: 'capitalize' }}> {deleteModal.language} </Box>
                        language?
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        gap: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <RadiusButton
                            variant='outlined'
                            onClick={handleCloseDeleteModal}
                        >
                            No
                        </RadiusButton>
                        <RadiusButton
                            variant='contained'
                            onClick={handleDeleteAllConfirm}
                        >
                            Yes
                        </RadiusButton>
                    </Box>

                </Box>
            </Modal>
            <ContentUploadErrorsModal currentState={errorModal} setCurrentState={setErrorModal}></ContentUploadErrorsModal>
        </Fragment>
    );
}