import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Typography, Switch, TextField, FormControlLabel, FormControl, InputLabel, Select, OutlinedInput, MenuItem, FormHelperText } from '@mui/material';
import { Container, MainContainer } from '../../../../components';
import { UserCreationMail } from '../UserCreationMail/UserCreationMail';
import { Menu } from '../Menu/Menu';
import moduleTypes from '../../../../constant/moduleTypes';
import { useToaster } from '../../../../providers/ToasterProvider';

import { createUser, getById, updateById } from '../../../../services/usersService';

import RadiusButton from '../../../../components/RadiusButton';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import BACK from '../../../../assets/images/back.svg';

import './UserDetails.scss';

export const UserDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { addNotification } = useToaster();

    const [modules, setModules] = useState([]);
    const [modulesError, setModulesError] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [mailModal, setMailModal] = useState({
        isOpen: false,
        cancelPressed: false
    });
    const [isActive, setIsActive] = useState(true);
    const projectName = params?.projectName;

    const userSchema = z.object({
        firstName: z.string().min(1, { message: 'First name is required.' }),
        lastName: z.string().min(1, { message: 'Last name is required.' }),
        mobile: z.string().regex(new RegExp('^[0-9]*$'), { message: 'Please enter valid mobile number.' }).length(10, { message: 'Please enter valid mobile number.' }),
        email: z.string().min(1, { message: 'Email id is required' }).email('Please enter valid email address.'),
        password: userDetails ? z.string().optional() : z.string().min(8, 'Password should be at least 8 characters long.'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        setValue,
        reset,
    } = useForm({
        resolver: zodResolver(userSchema),
        // defaultValues: {
        //     firstName: 'jayesh',
        //     lastName: 'vora',
        //     mobile: '8888888888',
        //     email: 'jayesh@mailinator.com',
        //     password: 'test@123'
        // }
    });

    useEffect(() => {
        if (location?.state?.userId) {
            const fetchUserDetails = async () => {
                try {
                    const res = await getById(location.state.userId);
                    if (res.code === 'OK') {
                        setEditMode(true);
                        const userData = res.data;
                        setValue('firstName', userData.firstName);
                        setValue('lastName', userData.lastName);
                        setValue('mobile', userData.mobile);
                        setValue('email', userData.email);
                        // setValue('password', userData.password);
                        const assignedModules = userData.modules.map((m) => +m);
                        const tags = moduleTypes.filter((module) => assignedModules.includes(module.code)).map((m) => m.displayKey);
                        setModules(tags);
                        setIsActive(userData.isActive);
                        setUserDetails(userData);
                    }
                } catch (error) {
                    console.log('There is an error while getting user details', error);
                }
            }
            fetchUserDetails();
        }
    }, [location, setValue, setModules]);

    useEffect(() => {
        if (mailModal.cancelPressed) {
            const path = location.pathname.replace('/user-details', '');
            navigate(path);
        }
    }, [mailModal, navigate, location]);

    const submitForm = async (values) => {
        if (modules.length === 0) {
            setModulesError(true);
            return;
        }

        const body = {
            ...values,
            isActive: isActive,
            modules: moduleTypes.filter((module) => modules.includes(module.displayKey)).map((m) => m.code)
        }
        if (editMode) {
            if (!userDetails?.id) return;
            if (!body.password) {
                delete body.password;
            }
            try {
                const res = await updateById(userDetails.id, body);
                if (res?.code === 'OK') {
                    addNotification('User updated successfully.', 'success');
                }
            } catch (error) {
                console.log('There is an error while updating user', error);
            }
        } else {
            try {
                body.userType = 3;
                const res = await createUser(body);
                if (res?.code === 'CREATED') {
                    const data = {
                        ...res.data.user,
                        password: values.password
                    }
                    setUserDetails(data);
                    setMailModal({
                        ...mailModal,
                        isOpen: true,
                    })
                    addNotification('User created successfully.', 'success');
                    reset();
                }
            } catch (error) {
                if (error?.response?.data?.data?.code === 'E_DUPLICATE') {
                    addNotification('User email address already exists.', 'danger');
                }
                console.log('There is an error while creating user', error);
            }
        }
    }

    const onGoBack = () => {
        const path = location.pathname.replace('/user-details', '');
        navigate(path);
    }

    const handleChange = (event) => {
        if (modulesError) {
            setModulesError(false);
        }
        const value = event.target.value;
        setModules(value);
    };

    const handleActiveChange = () => {
        setIsActive(!isActive);
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{
                        fontWeight: 400,
                        textTransform: 'capitalize'
                    }}>{projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>
                        {editMode ? 'Edit User' : 'Create User'}
                    </Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>

                <Typography component='h5' variant='h5' sx={{
                    mb: 3
                }}>
                    {editMode ? 'Edit User' : 'Create User'}
                </Typography>

                <Box sx={{
                    bgcolor: 'background.base',
                    borderRadius: 6,
                    p: 4
                }} className='create-user-form-wrapper'>
                    <Box component='form' onSubmit={handleSubmit(submitForm)}>
                        <div className="user-form-details">

                            <TextField
                                id='firstName'
                                name='firstName'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>First Name</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('firstName')}
                                error={Boolean(errors.firstName)}
                                helperText={errors.firstName?.message}
                                onBlur={() => trigger('firstName')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                            />

                            <TextField
                                id='lastName'
                                name='lastName'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>Last Name</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('lastName')}
                                error={Boolean(errors.lastName)}
                                helperText={errors.lastName?.message}
                                onBlur={() => trigger('lastName')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                            />

                            <TextField
                                id='mobile'
                                name='mobile'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>Mobile No</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('mobile')}
                                error={Boolean(errors.mobile)}
                                helperText={errors.mobile?.message}
                                onBlur={() => trigger('mobile')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                            />

                            <TextField
                                id='email'
                                name='email'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>Email Id</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('email')}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                                onBlur={() => trigger('email')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                                disabled={editMode ? true : false}
                            />

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <TextField
                                    id='password'
                                    name='password'
                                    className='form-field'
                                    label={
                                        <>
                                            <Box component='span'>Password</Box>
                                            <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                        </>
                                    }
                                    type='password'
                                    variant='outlined'
                                    {...register('password')}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password?.message}
                                    onBlur={() => trigger('password')}
                                    InputLabelProps={editMode ? { shrink: true } : {}}
                                />
                                {userDetails?.id && <FormHelperText sx={{
                                    mt: '-10px',
                                    color: 'primary.main'
                                }}>
                                    Enter password only if you wish to update.
                                </FormHelperText>}
                            </Box>

                            <FormControl>
                                <InputLabel id="createUserModulesLabel">
                                    <Box component='span'>Module</Box>
                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                </InputLabel>
                                <Select
                                    labelId="createUserModulesLabel"
                                    id="module"
                                    multiple
                                    value={modules}
                                    onChange={handleChange}
                                    input={
                                        <OutlinedInput id="select-multiple-chip" label='Module' />
                                    }
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.75 }}>
                                            {selected.map((value) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: 1.5,
                                                    bgcolor: 'primary.50',
                                                    py: 0.5,
                                                    px: 1.5,
                                                    borderRadius: 1
                                                }} key={value}>
                                                    <span>{value}</span>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {moduleTypes.map((module) => (
                                        <MenuItem
                                            key={module.code}
                                            value={module.displayKey}
                                        >
                                            {module.displayKey}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {modulesError && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please assign at least one module
                                    </FormHelperText>
                                )}

                            </FormControl>

                        </div>

                        <FormControlLabel sx={{
                            mt: 2,
                            mb: 3
                        }} control={
                            <Switch
                                checked={isActive}
                                onChange={handleActiveChange}
                            />
                        } label='Active' />

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <RadiusButton sx={{
                                textTransform: 'uppercase',
                                fontWeight: 500,
                            }} color='grey'
                                variant='contained'
                                size='large'
                                // disabled
                                onClick={onGoBack}
                                startIcon={<img src={BACK} alt='' />}>
                                Back
                            </RadiusButton>
                            <RadiusButton sx={{
                                textTransform: 'uppercase',
                                fontWeight: 500,
                            }} color='primary'
                                type="submit"
                                variant='contained'
                                size='large'>
                                Submit
                            </RadiusButton>
                        </Box>


                    </Box>
                </Box>

            </Container>
            <UserCreationMail userDetails={userDetails} openState={mailModal.isOpen} setMailModal={setMailModal} />
        </MainContainer >
    );
}
