import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, List, ListItemButton } from "@mui/material";

import { AuthContext } from "../../../../providers/auth"; 
import useClickoutHook from "../../../../hooks/useClickoutHook"; 

// import NOTIFICATION_BELL from '../../assets/images/notification-bell.svg';
import USER_PROFILE from '../../../../assets/images/user-profile.svg';
import USER_MORE from '../../../../assets/images/user-more.svg';
import { logout } from "../../../../services/authService";
import './Menu.scss';
import { ProjectContext } from "../../../../providers/projectProvider";

export const Menu = ({ children, menuVisible = true }) => {

    const { user, setUser } = useContext(AuthContext);
    const { projectDetails } = useContext(ProjectContext);
    const navigate = useNavigate();

    const [userMenuToggler, setUserMenuToggler] = useState(false);
    const handleLogout = async () => {
        const projectName = projectDetails?.projectShortName ? projectDetails.projectShortName : 'list';
        let redirectPath = `/${projectName}/login`;
        await logout();
        setUser(null);
        navigate(redirectPath);
    }

    const menuWrapper = useRef(null);
    useClickoutHook(menuWrapper, () => setUserMenuToggler(false));

    return (
        <Box sx={{ display: 'flex', py: 1.5, px: 3, alignItems: 'center', justifyContent: 'flex-start', height: 80, top: 0, gap: 1.5 }}>
            {
                children
                    ? children
                    : <div></div>
            }

            <div className='menu-wrapper'>
                {/* <div className='notification-wrapper'>
                    <img className='icon' src={NOTIFICATION_BELL} alt='bell' />
                </div> */}

                {
                    menuVisible && user && <div className='user-wrapper'>
                        <div className='user-toggler-wrapper' onClick={() => setUserMenuToggler(!userMenuToggler)}>
                            <div className='user-toggler'>
                                <img className='icon' src={USER_PROFILE} alt='profile' />
                                <div className='more-toggler'>
                                    <img className='icon' src={USER_MORE} alt='more' />
                                </div>
                            </div>
                        </div>
                        {
                            userMenuToggler && <div className='list-wrapper' ref={menuWrapper}>
                                <List component="nav" aria-label="secondary mailbox folder" sx={{ bgcolor: 'background.base' }}>
                                    <ListItemButton onClick={handleLogout}>
                                        Logout
                                    </ListItemButton>
                                </List>
                            </div>
                        }
                    </div>
                }

            </div>
        </Box>
    );
}