import Axios from 'axios';
import storage from '../config/storage';
import { axios } from '../config/axios';

const endPoint = '/project/configuration';

const getById = (configurationId) => {
    return axios.get(`${endPoint}/${configurationId}`);
}

const getDetailsWithQuestionsStatusById = (configurationId) => {
    return axios.get(`${endPoint}/details-with-questions-status/${configurationId}`);
}

const create = (body) => {
    return axios.post(`${endPoint}`, body);
}

const updateById = (id, body) => {
    return axios.post(`${endPoint}/${id}`, body);
}

const getFeedbackAndInstructionsEnabled = (configurationId) => {
    return axios.get(`${endPoint}/feedback-and-instructions-enabled/${configurationId}`);
}

const getInstructionSheetsEnabled = (configurationId) => {
    return axios.get(`${endPoint}/instruction-sheets-enabled/${configurationId}`);
}

const getFeedbackEnabled = (configurationId) => {
    return axios.get(`${endPoint}/feedback-enabled/${configurationId}`);
}

const getDataWithSets = (configurationId) => {
    return axios.get(`${endPoint}/data-with-sets/${configurationId}`);
}

const getFeedbackQuestions = (configurationId) => {
    return axios.get(`${endPoint}/feedback-questions/${configurationId}`);
}

const addFeedbackQuestions = (body) => {
    return axios.post(`${endPoint}/add-feedback-questions`, body);
}

const deleteFeedbackQuestoinsByLanguage = (body) => {
    return axios.post(`${endPoint}/delete-feedback-questions`, body);
}

const lockExam = (configurationId) => {
    return axios.post(`${endPoint}/lock-exam/${configurationId}`);
}

const downloadFeedbackTemplate = () => {
    const token = storage.getToken();
    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-feedback-template`, {
        responseType: 'blob',
        headers: {
            Authorization: token
        }
    });
}

export {
    getById,
    getDetailsWithQuestionsStatusById,
    create,
    updateById,
    getFeedbackAndInstructionsEnabled,
    getInstructionSheetsEnabled,
    getFeedbackEnabled,
    getDataWithSets,
    getFeedbackQuestions,
    addFeedbackQuestions,
    deleteFeedbackQuestoinsByLanguage,
    lockExam,
    downloadFeedbackTemplate,
}