import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';

import { AuthContext } from '../../../../providers/auth';
import { useToaster } from '../../../../providers/ToasterProvider';

import { superAdminLogin } from '../../../../services/authService';

import { MainContainer, Container } from '../../../../components';
import { SuperAdminMenu } from '../SuperAdminMenu/SuperAdminMenu';
import RadiusButton from '../../../../components/RadiusButton'

import EYE_ICON from '../../../../assets/images/eye.svg';
import EYE_CLOSE_ICON from '../../../../assets/images/eye-close.svg'

import './SuperAdminLogin.scss';


const loginSchema = z.object({
    email: z.string().min(1, { message: 'Email is required' }).email('Invalid email address'),
    password: z.string().min(8, 'Password should be at least 8 characters long'),
});

export const SuperAdminLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser } = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const { addNotification } = useToaster();

    useEffect(() => {
        if (user?.email && user?.userType === 1) {
            navigate('/projects');
        }
    }, [location, user, navigate]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger
    } = useForm({
        resolver: zodResolver(loginSchema),
    });

    const onSubmit = async (data) => {
        try {
            const user = await superAdminLogin(data);
            if (user) {
                setUser(user);
                navigate('/projects');
            }
        } catch (error) {
            if (error?.response?.data?.code === 'E_BAD_REQUEST') {
                addNotification('User credentials are wrong.', 'danger')
            }
            if (error?.response?.data?.code === 'E_USER_NOT_FOUND') {
                addNotification('User credentials are wrong.', 'danger')
            }
            console.log('There is an error while logging in', error);
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    return (
        <MainContainer>
            <SuperAdminMenu></SuperAdminMenu>
            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
            }}>
                <Box sx={{
                    width: '80%',
                    maxWidth: 724,
                    px: 4.5,
                    py: 3,
                    borderRadius: 6,
                    bgcolor: 'background.base'
                }} className='login-container' component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant='h5'>User Log In</Typography>
                    <div className='login-wrapper'>
                        <TextField
                            id='email'
                            fullWidth name='email'
                            placeholder='Email Address'
                            autoFocus label={
                                <>
                                    <Box component='span'>Email</Box>
                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                </>
                            }
                            variant='outlined'
                            {...register('email')}
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                            onBlur={() => trigger('email')}
                        />

                        <FormControl variant='outlined'>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id='password'
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            <img className='password-eye' src={showPassword ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                name="password"
                                autoComplete="current-password"
                                {...register('password')}
                                error={Boolean(errors.password)}
                                onBlur={() => trigger('password')}
                            />
                            {errors?.password?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    { errors.password?.message }
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                    <div className='align-center'>
                        <RadiusButton color="secondary" type="submit" variant='contained' size='large'>Log In</RadiusButton>
                    </div>
                </Box>
            </Container>
        </MainContainer>
    );
}