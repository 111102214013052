import { createContext, useEffect, useState } from "react";
import { getDetailsByShortName } from "../services/projectService";

import storage from '../config/storage';

export const ProjectContext = createContext();

const ProjectContextProvider = ({ children }) => {
    const [projectDetails, setProjectDetails] = useState(null);

    const setCurrentProjectDetails = async (projectShortName) => {
        try {
            if (projectShortName === null) {
                setProjectDetails(null);
                storage.clearProjectShortName();
                return;
            }
            const response = await getDetailsByShortName(projectShortName);
            if (!response?.data?.id) {
                setProjectDetails(null);
                return;
            }
            setProjectDetails(response.data);
            storage.setProjectShortName(response.data.projectShortName)
        } catch (error) {
            console.log('There is an error while getting project details by short name', error);
            setProjectDetails(null);
        }
    }

    useEffect(() => {
        const projectName = storage.getProjectShortName();
        async function fetchData(projectName) {
            await setCurrentProjectDetails(projectName);
        }
        if (projectName) {
            fetchData(projectName);
        }
    }, []);

    return (
        <ProjectContext.Provider value={{ projectDetails, setCurrentProjectDetails }}>
            {children}
        </ProjectContext.Provider>
    );

}

export default ProjectContextProvider;