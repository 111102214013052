import { useContext, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemButton, Typography, ListItemAvatar, Avatar } from "@mui/material"

import { logout } from "../../services/authService";
import storage from "../../config/storage";
import { ProjectContext } from "../../providers/projectProvider";
import { AuthContext } from "../../providers/auth";

import BRAND from '../../assets/images/logo.png';
import DASHBOARD_ICON from '../../assets/images/dashboard.svg';
import USER_MANAGEMENT_ICON from '../../assets/images/users-management.svg';
import MASTER_MANAGEMENT_ICON from '../../assets/images/master-management.svg';
import DOCUMENT_UPLOAD from '../../assets/images/document-upload.svg';
import LOGOUT_ICON from '../../assets/images/logout.svg';
import SCORE_ICON from '../../assets/images/raw-scores.svg';

export const SidebarMenu = ({ isFloating, children }) => {
    const drawerWidth = 85;

    const params = useParams();
    const navigate = useNavigate();
    const { projectDetails } = useContext(ProjectContext);
    const { user, setUser } = useContext(AuthContext);


    useEffect(() => {
        if (projectDetails && projectDetails.projectName) {
            const sections = projectDetails.projectName.split(' ').filter((section) => section);
            const projectName = sections.map((section) => {
                return section[0].toUpperCase() + section.slice(1);
            }).join(' ');
            document.title = projectName;
        } else {
            document.title = 'HubbleHox';
        }
    }, [projectDetails]);

    useEffect(() => {
        if (!projectDetails) {
            const projectName = storage.getProjectShortName() || params?.projectName;
            if (!projectName) {
                navigate('/list');
            }
        }
    }, [projectDetails, navigate]);

    const handleLogout = async () => {
        await logout();
        setUser(null);
        navigate(`/${projectDetails?.projectShortName}/login`);
    }

    const goToContentDashboard = () => {
        navigate(`/${projectDetails?.projectShortName}/content-dashboard`);
    }
    const goToMastersManagement = () => {
        navigate(`/${projectDetails?.projectShortName}/masters`);
    }
    const goToUserManagement = () => {
        navigate(`/${projectDetails?.projectShortName}/users`);
    }
    const goToDocumentUpload = () => {
        navigate(`/${projectDetails?.projectShortName}/documents`);
    }
    const goToScoreManagement = () => {
        navigate(`/${projectDetails?.projectShortName}/score-management`);
    }
    const goToCustomerDashboard = () => {
        navigate(`/${projectDetails?.projectShortName}/customer/dashboard`);
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    border: 'unset',
                    bgcolor: isFloating ? 'background.default' : 'background.base'
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Box sx={{
                p: 1,
                height: 80,
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: isFloating ? 'background.default' : 'background.base',
                borderBottom: '1px solid',
                borderColor: isFloating ? 'transparent' : 'grey.300'
            }}>
                {
                    projectDetails?.logo
                        // ? <img className='brand-image' src={`${process.env.REACT_APP_API_URL.replace('/api', '')}/${projectDetails?.logo}`} alt='' />
                        ? <img className='brand-image' src={`${projectDetails?.logo}`} alt='' />
                        : <img className='brand-image' src={BRAND} alt='' />
                }
            </Box>

            {
                !isFloating && <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                    <List>
                        {
                            user?.userType === 2 && (
                                <ListItem className='active' onClick={goToMastersManagement}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={MASTER_MANAGEMENT_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Masters Management
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        {
                            user?.userType === 2 && (
                                <ListItem className='active' onClick={goToUserManagement}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={USER_MANAGEMENT_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            User Management
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        {
                            user?.userType === 2 && (
                                <ListItem className='active' onClick={goToDocumentUpload}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={DOCUMENT_UPLOAD} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Document Upload
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        {
                            user?.userType === 3 && user?.modules.includes(101) && (
                                <ListItem className='active' onClick={goToContentDashboard}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={DASHBOARD_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Content Dashboard
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        {
                            user?.userType === 3 && user?.modules.includes(103) && (
                                <ListItem className='active' onClick={goToScoreManagement}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={SCORE_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Score Management
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        {
                            user?.userType === 3 && user?.modules.includes(104) && (
                                <ListItem className='active' onClick={goToCustomerDashboard}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={DASHBOARD_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Dashboard
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        {
                            user?.userType === 4 && (
                                <ListItem className='active' onClick={goToContentDashboard}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={DASHBOARD_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Content Dashboard
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }

                    </List>
                </Box>
            }
            {
                !isFloating && <ListItem sx={{
                    pb: 3
                }} className='active' onClick={handleLogout}>
                    <ListItemButton sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flexGrow: 1,
                        '&:hover': {
                            bgcolor: 'unset',
                            color: 'primary.main'
                        },
                        maxWidth: 56,
                        padding: 0
                    }} disableRipple disableGutters>
                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                            <Avatar sx={{
                                color: 'inherit',
                                width: 56,
                                height: 32,
                                borderRadius: 16,
                                bgcolor: 'transparent',
                                '&:hover': {
                                    bgcolor: 'primary.100'
                                }
                            }}>
                                <img src={LOGOUT_ICON} alt='' />
                            </Avatar>
                        </ListItemAvatar>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: 12,
                            fontWeight: 400,
                            lineHeight: 1.2,
                        }}>
                            Logout
                        </Typography>
                    </ListItemButton>
                </ListItem>
            }


        </Drawer>
    )
}
