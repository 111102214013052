import { Fragment, useState } from 'react';
import { Box, Typography, Modal, FormControlLabel, Switch } from '@mui/material';

import { useToaster } from '../../../../providers/ToasterProvider';
import { createBulkUsers, downloadUserTemplate } from '../../../../services/usersService';

import RadiusButton from '../../../../components/RadiusButton';

import DOWNLOAD_BLUE from '../../../../assets/images/download-blue.svg';
import DELETE_ICON from '../../../../assets/images/action-delete.svg';

export const BulkUserUploadModal = ({ isVisible, setIsVisible, userType, dataUpdated, setDataUpdated }) => {

    const [file, setFile] = useState(null);
    const [sendMail, setSendMail] = useState(false);
    const [uploadResult, setUploadResult] = useState({
        isVisible: false,
        createdRecordsCount: 0,
        errors: []
    });
    const { addNotification } = useToaster();

    const handleFileChange = ({ target }) => {
        const file = target?.files[0];
        if (file && file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(file);
        }
    }
    const handleFileRemove = () => {
        const element = document.getElementById('userFile');
        if (element) {
            element.value = '';
            setFile(null);
        }
    }
    const handleModalClose = () => {
        setIsVisible(false);
        setFile(null);
        const ele = document.getElementById('userFile');
        if (ele) {
            ele.value = '';
        }
    }
    const handleDownloadTemplate = async () => {
        try {
            const res = await downloadUserTemplate();
            const url = window.URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `users-template.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            console.log('There is an error while downloading user template.', error);
        }
    }
    const handleFileUpload = async () => {
        if (!file || !userType) return;
        try {
            const body = new FormData();
            body.append('userType', userType);
            body.append('sendMail', sendMail);
            body.append('file', file);
            const res = await createBulkUsers(body);
            setUploadResult({
                isVisible: true,
                createdRecordsCount: res.data.createdRecordsCount,
                errors: res.data.errors
            });
            const ele = document.getElementById('userFile');
            if (ele) {
                ele.value = '';
            }
        } catch (error) {
            console.log('There is an error while uploading bulk users', error);
        }
    }
    const handleResultModalClose = () => {
        setUploadResult({
            ...uploadResult,
            isVisible: false,
        });
        if (uploadResult.createdRecordsCount > 0) {
            setDataUpdated(!dataUpdated);
        }
        setFile(null);
        setIsVisible(false);
    }

    return (
        <Fragment>
            <Modal
                open={isVisible}
                onClose={handleModalClose}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.base',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 3,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 3,
                        gap: 1,
                    }}>
                        <Typography variant="h6" component="h6" sx={{
                            color: 'primary.main'
                        }}>
                            Bulk upload users
                        </Typography>
                        <RadiusButton
                            variant='outlined'
                            startIcon={<img src={DOWNLOAD_BLUE} />}
                            onClick={handleDownloadTemplate}
                        >
                            Download Template
                        </RadiusButton>
                    </Box>

                    <Box sx={{
                        pb: 2,
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}>
                        <RadiusButton
                            variant='contained'
                            component='label'
                            htmlFor='userFile'
                            color='secondary'
                        >
                            Select File
                        </RadiusButton>
                        <input
                            style={{ display: 'none' }}
                            type='file'
                            id='userFile'
                            onChange={handleFileChange}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        />
                        {
                            file && file?.name && <Fragment>
                                <Typography variant='subtitle1' >{file.name}</Typography>
                                <img
                                    style={{ cursor: 'pointer' }}
                                    src={DELETE_ICON}
                                    onClick={handleFileRemove}
                                />
                            </Fragment>
                        }
                    </Box>
                    <FormControlLabel
                        sx={{
                            mb: 2
                        }}
                        control={
                            <Switch
                                checked={sendMail}
                                onChange={(e, value) => setSendMail(value)}
                            />
                        }
                        label="Send mail"
                    />

                    <Box className='actions-container'>
                        <RadiusButton
                            variant='contained'
                            color='grey'
                            sx={{ ml: 'auto' }}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </RadiusButton>
                        <RadiusButton
                            variant='contained'
                            onClick={handleFileUpload}
                            disabled={!file}
                        >
                            Upload
                        </RadiusButton>
                    </Box>

                </Box>
            </Modal>
            <Modal open={uploadResult.isVisible}
                onClose={handleResultModalClose}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.base',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 3,
                }}>
                    <Typography variant="h5" component="h5" sx={{
                        mb: 1.5,
                        color: 'primary.main'
                    }}>
                        Users upload status
                    </Typography>
                    <Typography variant="h6" component="h6" sx={{
                        mb: 1.5,
                    }}>
                        Successfully created users count: {uploadResult.createdRecordsCount}
                    </Typography>

                    {uploadResult?.errors?.length
                        ? <Fragment>
                            <Typography variant="h6" component="h6" sx={{
                                mb: 1,
                            }}>
                                Errors
                            </Typography>
                            <Box sx={{
                                mb: 3,
                                overflow: 'auto',
                                maxHeight: 320
                            }}>
                                {
                                    uploadResult.errors.map((error, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <Typography variant="h6" sx={{ ml: 2, fontSize: 14 }}>
                                                    For record number {error.recordIndex} {error.errors.join(', ')}
                                                </Typography>
                                            </Fragment>
                                        )
                                    })
                                }
                            </Box>
                        </Fragment>
                        : null}

                    <Box className='actions-container'>
                        <RadiusButton variant='contained' sx={{ ml: 'auto' }} onClick={handleResultModalClose}>
                            Okay
                        </RadiusButton>
                    </Box>

                </Box>
            </Modal>
        </Fragment>
    );
}
