import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Typography, Box, Radio, Modal, RadioGroup, Stack, FormControlLabel, Autocomplete, TextField } from '@mui/material';

import { MainContainer } from '../../../../components/MainContainer/MainContainer';
import { Container } from '../../../../components/Container/Container';
import { Menu } from '../../../../components/Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import ARROW_LEFT from '../../../../assets/images/back.svg';
import NEXT from '../../../../assets/images/content-next.svg';

import './FeedbackPreview.scss';
import { getForPreview } from '../../../../services/feedbackQuestionService';
import { useToaster } from '../../../../providers/ToasterProvider';

export const FeedbackPreview = () => {

    const params = useParams();
    const projectName = params?.projectName;
    const location = useLocation();
    const navigate = useNavigate();
    const [drive, setDrive] = useState(null);
    const [course, setCourse] = useState(null);
    const [configurationId, setConfigurationId] = useState(null);
    const [language, setLanguage] = useState(null);
    const [question, setQuestion] = useState(null);
    const [questionNo, setQuestionNo] = useState(null);
    const [maxQuestions, setMaxQuestions] = useState(0);
    const { addNotification } = useToaster();
    const [preview, setPreview] = useState({
        previewNo: '',
        inputNo: '',
        options: [],
    })

    useEffect(() => {
        const questionNo = location?.state?.questionNo;
        const drive = location?.state?.drive;
        const course = location?.state?.course;
        const configurationId = location?.state?.configurationId;
        const maxQuestions = location?.state?.maxQuestions;
        const language = location?.state?.language;
        if (questionNo && drive && course && configurationId) {
            setDrive(drive);
            setCourse(course);
            setLanguage(language);
            setConfigurationId(configurationId);
            setQuestionNo(Number(questionNo));
            setMaxQuestions(Number(maxQuestions));
            setPreview({
                previewNo: '',
                inputNo: '',
                options: Array.from({ length: maxQuestions + 1 }, (_, i) => i ? i.toString() : '')
            });
        }
    }, [navigate]);

    useEffect(() => {
        if (questionNo) {
            const fetchDetails = async () => {
                try {
                    const body = {
                        questionNo: questionNo,
                        configurationId: configurationId,
                        language: language,
                        imageSize: 'L'
                    }
                    const res = await getForPreview(body);
                    if (res.code === 'OK') {
                        setQuestion(res.data);
                    }
                } catch (error) {
                    console.log('There is an error while getting question details', error);
                }
            }
            fetchDetails();
        }
    }, [questionNo]);

    const onGoBack = () => {
        navigate(`/${projectName}/upload`, {
            state: {
                activeTab: 'feedback',
                configurationId: configurationId,
                drive: drive,
                course: course,
            }
        });
    }

    const handlePrevious = () => {
        if (questionNo === 1) return;
        setQuestionNo(questionNo - 1);

    }
    const handleNext = async () => {
        if (questionNo < maxQuestions) {
            setQuestionNo(questionNo + 1);
        }
    }
    const setPreviewQuestionNo = (value) => {
        if (value) {
            setPreview({
                ...preview,
                previewNo: value,
            })
        }
    }
    const setPreviewQuestionInput = (value) => {
        setPreview({
            ...preview,
            inputNo: value
        });
    }
    const onView = () => {
        if (!preview.previewNo) {
            addNotification('Please select question no.')
        } else {
            setQuestionNo(Number(preview.previewNo));
        }
    }


    return (
        <MainContainer >
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400 }}>Dashboard</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Feedback Preview</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5' component='h5' sx={{ mb: 2 }}>Feedback Preview</Typography>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    backgroundColor: 'background.base',
                    borderRadius: 4,
                    maxWidth: 'calc(100vw - 135px)',
                    mb: 1.5,
                    px: 1,
                    py: 1.5,
                    overflow: 'auto'
                }}>
                    <Typography variant='h6' component='h6' sx={{
                        px: 2,
                        fontSize: 16,
                        color: 'primary.main',
                        mb: 4
                    }}
                    >Question Number - {question?.questionNo}</Typography>
                    <Box sx={{
                        px: 2,
                        mb: 2.5
                    }}
                    >
                        {question?.question && (
                            <img src={question.question} alt='' />
                        )}
                    </Box>
                    <RadioGroup name="answer">
                        {
                            question?.options?.map((option, index) => {
                                return <Box key={index} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    ml: 2,
                                    cursor: 'pointer'
                                }}>
                                    <Radio disabled id={`option_${index + 1}`} value={index + 1} disableRipple />
                                    <label htmlFor={`option_${index + 1}`} style={{ cursor: 'pointer' }}>
                                        <img key={index} src={option} alt='' />
                                    </label>
                                </Box>
                            })
                        }
                    </RadioGroup>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 1,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}>
                        <Autocomplete
                            value={preview.previewNo}
                            onChange={(event, newValue) => {
                                setPreviewQuestionNo(newValue);
                            }}
                            inputValue={preview.inputNo}
                            onInputChange={(event, newInputValue) => {
                                setPreviewQuestionInput(newInputValue);
                            }}
                            id="lan"
                            options={preview.options}
                            sx={{ width: 180 }}
                            renderInput={(params) => <TextField {...params} label="Question No" />}
                        />
                        <RadiusButton sx={{
                            fontWeight: 500,
                            textTransform: 'uppercase'
                        }} variant='contained' color='primary'
                            size='large' onClick={onView}>
                            View
                        </RadiusButton>
                    </Box>
                    <Stack sx={{ display: 'flex', justifyContent: 'right' }} direction="row" spacing={2}>
                        <RadiusButton sx={{
                            fontWeight: 500,
                            textTransform: 'uppercase'
                        }} variant='contained' color='grey' startIcon={<img src={ARROW_LEFT} alt='' />}
                            size='large' onClick={onGoBack}>
                            Back
                        </RadiusButton>
                        {questionNo > 1 && <RadiusButton color="primary"
                            variant='contained'
                            endIcon={<img style={{ transform: 'rotate(180deg)' }} src={NEXT} alt='' />}
                            size='large'
                            sx={{
                                fontWeight: 500,
                            }}
                            onClick={handlePrevious}
                        >PREVIOUS</RadiusButton>}
                        {questionNo < maxQuestions && <RadiusButton color="primary"
                            variant='contained'
                            startIcon={<img src={NEXT} alt='' />}
                            size='large'
                            sx={{
                                fontWeight: 500,
                            }}
                            onClick={handleNext}
                        >NEXT</RadiusButton>}
                        {questionNo === maxQuestions && <RadiusButton color="primary"
                            variant='contained'
                            size='large'
                            sx={{
                                fontWeight: 500,
                            }}
                            onClick={onGoBack}
                        >FINISH</RadiusButton>}
                    </Stack>
                </Box>
            </Container>
        </MainContainer>
    );
}
