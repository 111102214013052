import { Fragment, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ProjectContext } from "../providers/projectProvider";
import { AuthContext } from "../providers/auth";


const AdminAuthGuard = ({ component }) => {
    const { user } = useContext(AuthContext);
    const { projectDetails } = useContext(ProjectContext);
    const navigate = useNavigate();

    useEffect(() => {
        const projectName = projectDetails?.projectShortName ? projectDetails.projectShortName : 'list';
        if (!user) {
            if (projectName) {
                navigate(`/${projectName}/login`)
            } else {
                navigate('/list');
            }
        }

        // if (user?.userType === 2) {
        //     navigate('/list');
        // }
    }, [user, navigate, projectDetails]);

    return (
        <Fragment>{component}</Fragment>
    );
}

export default AdminAuthGuard;