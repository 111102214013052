import Axios from 'axios';
import { axios } from '../config/axios';
import storage from '../config/storage';

const endPoint = '/project/question';

const getById = (questionId) => {
    return axios.get(`${endPoint}/${questionId}`);
}

const getForPreview = (configurationId, language, setNumber, questionNo, imageSize, verifyQuestionNo = null) => {
    return axios.post(`${endPoint}/for-preview`, {
        configurationId,
        language,
        setNumber,
        questionNo,
        imageSize,
        verifyQuestionNo,
    });
}

const verifyQuestion = (questionId, userId) => {
    return axios.post(`${endPoint}/verify-question/${questionId}`, {
        userId: userId,
    });
}

const updateById = (questionId, body) => {
    return axios.post(`${endPoint}/${questionId}`, body);
}

const setWiseUpload = (body) => {
    return axios.post(`${endPoint}/set-wise-upload`, body);
}

const bulkUpload = (body) => {
    return axios.post(`${endPoint}/bulk-upload`, body);
}

const deleteAllBySet = (body) => {
    return axios.post(`${endPoint}/delete-all-by-set`, body);
}

const deleteAll = (body) => {
    return axios.post(`${endPoint}/delete-all`, body);
}

const downloadContentTemplate = () => {
    const token = storage.getToken();
    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-content-template`, {
        responseType: 'blob',
        headers: {
            authorization: token
        }
    });
}

const getPendingVerificationQuestionNumber = (body) => {
    return axios.post(`${endPoint}/pending-verification-question-number`, body);
}

export {
    getById,
    getForPreview,
    setWiseUpload,
    verifyQuestion,
    updateById,
    deleteAllBySet,
    downloadContentTemplate,
    getPendingVerificationQuestionNumber,
    bulkUpload,
    deleteAll,
}