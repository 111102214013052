import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Modal, TextField, Typography } from "@mui/material";

import { useToaster } from "../../../../providers/ToasterProvider";
import { createDrive } from "../../../../services/driveService";

import RadiusButton from '../../../../components/RadiusButton';

const driveNameSchema = z.object({
    driveName: z.string().min(1, { message: 'Drive name is required' }),
});


export const DriveDetailsModal = ({ openState, setOpenState }) => {

    const { addNotification } = useToaster();

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        reset,
    } = useForm({
        resolver: zodResolver(driveNameSchema),
    });

    const handleClose = () => {
        setOpenState(false);
        reset();
    };

    const onSubmit = async (data) => {
        try {
            const res = await createDrive(data);
            // console.log(res);
            addNotification('Drive created successfully', 'success');
            handleClose();
        } catch (error) {
            console.log(error);
            addNotification('Unexpected error occured', 'danger');
        }
        reset();
    }

    return (
        <Modal

            open={openState}
            onClose={handleClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 480,
                bgcolor: 'background.base',
                boxShadow: 24,
                borderRadius: 2,
                p: 3,
            }}>
                <Typography variant="h5" component="h5" sx={{
                    mb: 2.5,
                    color: 'primary.main'
                }}>
                    Add Drive
                </Typography>

                <Box component='form' sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        id='driveName'
                        name='driveName'
                        sx={{ width: '100%', maxWidth: 600 }}
                        label={
                            <>
                                <Box component='span'>Name</Box>
                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                            </>
                        }
                        variant='outlined'
                        {...register('driveName')}
                        error={Boolean(errors.driveName)}
                        helperText={errors.driveName?.message}
                        onBlur={() => trigger('driveName')}
                    />

                    <Box className='actions-container' sx={{
                        mt: 3
                    }}>
                        <RadiusButton variant='outlined' sx={{ ml: 'auto' }} onClick={handleClose}>
                            Cancel
                        </RadiusButton>
                        <RadiusButton type='submit' variant='contained' >
                            Add
                        </RadiusButton>
                    </Box>
                </Box>

            </Box>
        </Modal>
    );
}
