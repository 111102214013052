const storagePrefix = "dte_";

const storage = {
  getToken: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.sessionStorage.setItem(
      `${storagePrefix}token`,
      JSON.stringify(token)
    );
  },
  clearToken: () => {
    window.sessionStorage.removeItem(`${storagePrefix}token`);
  },
  getUserDetails: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}user_details`));
  },
  setUserDetails: (userDetails) => {
    window.sessionStorage.setItem(
      `${storagePrefix}user_details`,
      JSON.stringify(userDetails)
    );
  },
  clearUserDetails: () => {
    window.sessionStorage.removeItem(`${storagePrefix}user_details`);
  },
  getProjectShortName: () => {
    const value = window.sessionStorage.getItem(`${storagePrefix}project_short_name`);
    if (!value) {
      return null;
    } else {
      return JSON.parse(value);
    }
  },
  setProjectShortName: (projectShortName) => {
    window.sessionStorage.setItem(
      `${storagePrefix}project_short_name`,
      JSON.stringify(projectShortName)
    );
  },
  clearProjectShortName: () => {
    window.sessionStorage.removeItem(`${storagePrefix}project_short_name`);
  },
};

export default storage;
