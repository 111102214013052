import { axios } from '../config/axios';
import storage from '../config/storage';
import { jwtDecode } from 'jwt-decode';

const handleLoginResponse = (data) => {
    const decoded = jwtDecode(data.token.accessToken);
    if (!decoded?.user) return;

    data.user = {
        ...decoded.user,
        ...data.user
    }
    storage.setToken(data.token.accessToken);
    storage.setUserDetails(data.user);
    return data.user;
}

// const sync = async () => {
//     return new Promise((resolve) => {
//         axios.get('/admin/auth/sync').then((res) => {
//             resolve(res.data);
//         }).catch((err) => {
//             resolve(null);
//             console.log('There is an error while synchronizing', err);
//         });
//     })
// }

const superAdminLogin = async (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/admin/auth/login`, data).then((res) => {
            const userData = handleLoginResponse(res.data);
            resolve(userData);
        }).catch((error) => {
            reject(error);
        });
    });
}

const login = async (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/auth/login`, data).then((res) => {
            const userData = handleLoginResponse(res.data);
            resolve(userData);
        }).catch((error) => {
            reject(error);
        });
    });
}

const logout = async () => {
    return new Promise((resolve) => {
        storage.clearToken();
        storage.clearUserDetails();
        resolve();
    })
    
    // axios.get(`/admin/auth/logout`).then((res) => {
    //     console.log(res);
    // }).catch((error) => {
    //     console.log('There is an error while logging out', error);
    // });
}

export {
    superAdminLogin,
    login,
    logout,
}