import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Pagination, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';

import { ProjectContext } from '../../../../providers/projectProvider';
import RadiusButton from '../../../../components/RadiusButton';

import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import CONTENT_ICON from '../../../../assets/images/content-blue.svg';
import MOCK_EXAM from '../../../../assets/images/mock-exam.svg';
import LIVE_EXAM from '../../../../assets/images/live-exam-white.svg';
import XLS_ICON from '../../../../assets/images/xls.svg';


export const CustomerLiveExam = () => {
    const navigate = useNavigate();
    const { projectDetails } = useContext(ProjectContext)

    const totalPages = 12;
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    const columns = [
        { id: 'state', label: 'State', minWidth: 120 },
        { id: 'district', label: 'District', minWidth: 120 },
        { id: 'venueName', label: 'Venue Name', minWidth: 120 },
        { id: 'scheduledCount', label: 'Scheduled Count', minWidth: 120 },
        { id: 'examServer', label: 'Exam Server', minWidth: 120 },
        { id: 'examStarted', label: 'Exam Started', minWidth: 120 },
        { id: 'biometricCount', label: 'Biometric Count', minWidth: 120 },
        { id: 'loggedIn', label: 'Logged In', minWidth: 120 },
        { id: 'examOver', label: 'Exam Over', minWidth: 120 },
        { id: 'rPack', label: 'RPack', minWidth: 120 },
        { id: 'Log', label: 'log', minWidth: 120 },
    ];
    const rows = [
        {
            state: 'Maharashtra',
            district: 'Pune',
            venueName: 'Test Center-1',
            scheduledCount: 220,
            examServer: true,
            examStarted: true,
            biometricCount: 220,
            loggedIn: 220,
            examOver: true,
            rPack: 220,
            Log: 220,
        }
    ];


    const goToContent = () => {
        if (projectDetails) {
            navigate(`/${projectDetails?.projectShortName}/customer/dashboard`);
        }
    }
    const goToMockExam = () => {
        if (projectDetails) {
            navigate(`/${projectDetails?.projectShortName}/customer/mock-exam`);
        }
    }

    return (
        <MainContainer>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography sx={{
                        fontWeight: 500,
                        color: 'text.primary'
                    }}>
                        Dashboard
                    </Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div className="search-header-wrapper">
                    <h1 className="title" style={{ marginRight: 'auto' }}>
                        Dashboard
                    </h1>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='outlined'
                        size='large'
                        startIcon={<img src={CONTENT_ICON} />}
                        onClick={goToContent}
                    >
                        Content
                    </RadiusButton>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='outlined'
                        size='large'
                        startIcon={<img src={MOCK_EXAM} />}
                        onClick={goToMockExam}
                    >
                        Mock Exam
                    </RadiusButton>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='contained'
                        size='large'
                        startIcon={<img src={LIVE_EXAM} />}
                    >
                        LIve Exam
                    </RadiusButton>
                </div>

                <div className="search-header-wrapper">

                    <FormControl sx={{
                        width: 238,
                        bgcolor: 'background.default',
                    }}>
                        <InputLabel>Course Name</InputLabel>
                        <Select
                            value={0}
                            label="Course Name"
                        >
                            <MenuItem value={0}>None</MenuItem>
                        </Select>
                    </FormControl>

                    <DatePicker />

                    <FormControl sx={{
                        width: 238,
                        bgcolor: 'background.default',
                        mr: 'auto'
                    }}>
                        <InputLabel>Batch Name</InputLabel>
                        <Select
                            value={0}
                            label="Batch Name"
                        >
                            <MenuItem value={0}>None</MenuItem>
                        </Select>
                    </FormControl>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="primary"
                        variant='contained'
                        size='large'
                    >
                        Select
                    </RadiusButton>
                </div>

                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    mb: 5,
                    gap: 2.75,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: 1,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'grey.500',
                        backgroundColor: 'background.base',
                        height: 170,
                        width: 200,
                        p: 3,
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: 'text.primary',
                            fontWeight: 500,
                        }}>
                            MA Bed
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                        }}>
                            <Box component='span' sx={{ color: 'text.primary' }}>1378</Box>
                            <Box component='span' >/</Box>
                            <Box component='span' sx={{ color: 'success.main' }}>1250</Box>
                            <Box component='span' >/</Box>
                            <Box component='span' sx={{ color: 'success.main' }}>128</Box>
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            color: 'primary.main'
                        }}>
                            90.25%
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: 1,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'grey.500',
                        backgroundColor: 'background.base',
                        height: 170,
                        width: 200,
                        p: 3,
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: 'text.primary',
                            fontWeight: 500,
                        }}>
                            MA Bed
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                        }}>
                            <Box component='span' sx={{ color: 'text.primary' }}>1378</Box>
                            <Box component='span' >/</Box>
                            <Box component='span' sx={{ color: 'success.main' }}>1250</Box>
                            <Box component='span' >/</Box>
                            <Box component='span' sx={{ color: 'success.main' }}>128</Box>
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            color: 'primary.main'
                        }}>
                            90.25%
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: 1,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'grey.500',
                        backgroundColor: 'background.base',
                        height: 170,
                        width: 200,
                        p: 3,
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: 'error.main',
                            fontWeight: 500,
                        }}>
                            12
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                        }}>
                            Cities
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: 1,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'grey.500',
                        backgroundColor: 'background.base',
                        height: 170,
                        width: 200,
                        p: 3,
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: 'error.main',
                            fontWeight: 500,
                        }}>
                            27
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                        }}>
                            Centers
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: 1,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'grey.500',
                        backgroundColor: 'background.base',
                        height: 170,
                        width: 200,
                        p: 3,
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: 'text.primary',
                            fontWeight: 500,
                        }}>
                            MA Bed
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                        }}>
                            <Box component='span' sx={{ color: 'text.primary' }}>1378</Box>
                            <Box component='span' >/</Box>
                            <Box component='span' sx={{ color: 'success.main' }}>1250</Box>
                            <Box component='span' >/</Box>
                            <Box component='span' sx={{ color: 'success.main' }}>128</Box>
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            color: 'primary.main'
                        }}>
                            90.25%
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: 1,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'grey.500',
                        backgroundColor: 'background.base',
                        height: 170,
                        width: 200,
                        p: 3,
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: 'text.primary',
                            fontWeight: 500,
                        }}>
                            BA llb
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                        }}>
                            <Box component='span' sx={{ color: 'text.primary' }}>1378</Box>
                            <Box component='span' >/</Box>
                            <Box component='span' sx={{ color: 'success.main' }}>1250</Box>
                            <Box component='span' >/</Box>
                            <Box component='span' sx={{ color: 'success.main' }}>128</Box>
                        </Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            color: 'primary.main'
                        }}>
                            90.25%
                        </Typography>
                    </Box>
                </Box>

                <div className="search-header-wrapper">
                    <div className='search-wrapper' style={{ margin: 0 }}>
                        <input type='text' className='search-box' placeholder='Search...' />
                        <img className='search-icon' src={SEARCH_ICON} alt='' />
                    </div>
                    <FormControl sx={{
                        width: 238,
                        bgcolor: 'background.default',
                        mr: 'auto'
                    }}>
                        <InputLabel>Course Name</InputLabel>
                        <Select
                            value={0}
                            label="Course Name"
                        >
                            <MenuItem value={0}>None</MenuItem>
                        </Select>
                    </FormControl>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary"
                        type="submit"
                        variant='contained'
                        startIcon={<img src={XLS_ICON} alt='' />}
                        size='large'
                    >
                        Download
                    </RadiusButton>
                </div>


                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => {
                                    return (
                                        <TableRow hover key={rowIndex}>
                                            {columns.map((column, index) => {
                                                const value = row[column.id];
                                                switch (column.id) {
                                                    case 'siNo':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {String(rowIndex + 1).padStart(2, '0')}
                                                        </TableCell>)
                                                    case 'course':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {value}
                                                        </TableCell>)
                                                    case 'examDate':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {value}
                                                        </TableCell>)
                                                    case 'batchNo':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {String(value).padStart(2, '0')}
                                                        </TableCell>)
                                                    case 'bilingualDataUploaded':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {value}
                                                        </TableCell>)
                                                    default:
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {value == true ?
                                                                <Box sx={{ color: '#06C270' }}>yes</Box>
                                                                :
                                                                <Box sx={{ color: 'red' }}>no</Box>
                                                            }
                                                        </TableCell>)
                                                }
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination sx={{
                    mt: 'auto',
                }} count={totalPages}
                    page={page}
                    onChange={handleChange}
                    shape="rounded"
                />

            </Container>

        </MainContainer>
    );
}
