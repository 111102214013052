import { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Typography, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Pagination, Modal } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { deleteAllCandidates, deleteMaster, getCandidateDataCsv, getListByPage } from '../../../../services/masterService';
import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton'
import { useToaster } from '../../../../providers/ToasterProvider';
import dayjs from '../../../../config/dayjs';

import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import ADD_USER from '../../../../assets/images/add-user.svg';
import ACTION_EDIT from '../../../../assets/images/action-edit.svg';
import UPLOAD from '../../../../assets/images/upload.svg';
import ACTION_DELETE from '../../../../assets/images/action-delete.svg';
import ACTION_DOWNLOAD from '../../../../assets/images/action-download.svg';
import CHIP_CLOSE from '../../../../assets/images/chip-close.svg';

import './Masters.scss';
import { CandidateUploadModal } from '../CandidateUploadModal/CandidateUploadModal';
let timer = null;

const columns = [
    { id: 'driveName', label: 'Drive Name', minWidth: 140 },
    { id: 'course', label: 'Course Name', minWidth: 140 },
    { id: 'batch', label: 'Batch Name', minWidth: 140 },
    { id: 'examDate', label: 'Exam Date', minWidth: 120 },
    { id: 'candidateCount', label: 'Candidates', minWidth: 256 },
    { id: 'actions', label: '', minWidth: 200 },
    { id: 'status', label: '', minWidth: 190 },
];
const limit = parseInt(process.env.REACT_APP_PAGE_LIMIT);

export const Masters = () => {

    const [masters, setMasters] = useState([]);
    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const { addNotification } = useToaster();
    const handlePageChange = (e, value) => {
        setCurrentPage(value)
    };
    const [search, setSearch] = useState('');
    const [newSearch, setNewSearch] = useState(false);
    const [candidateUploadModalData, setCandidateUploadModalData] = useState({
        isVisible: false,
        projectMasterId: null,
    });
    const [dataUpdated, setDataUpdated] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getListByPage(search, currentPage, limit);
                if (response?.code === 'OK') {
                    const totalPages = Math.ceil(response.data.count / limit);
                    setMaxPages(totalPages);
                    setMasters(response.data.rows);
                }
            } catch (error) {
                console.log('There is an error while fetching projects list', error);
            }
        }
        fetchData();
    }, [currentPage, newSearch, dataUpdated]);

    const navigate = useNavigate();
    const location = useLocation();

    const onAddMasterUser = () => {
        navigate(`${location.pathname}/master-details`);
    }
    const [selectedMasterId, setSelectedMasterId] = useState(null);
    const [deleteMasterToggler, setDeleteMasterToggler] = useState(false);
    const handelDeleteMaster = (masterIndex) => {
        const master = masters[masterIndex];
        if (master?.id) {
            setSelectedMasterId(master.id);
            setDeleteMasterToggler(true);
        }
    }
    const onConfirmDeleteMaster = async () => {
        if (!selectedMasterId) return;

        try {
            const response = await deleteMaster(selectedMasterId);
            if (response.code === 'OK') {
                setMasters(
                    masters.filter((master) => master.id !== selectedMasterId)
                );
                setSelectedMasterId(null);
                setDeleteMasterToggler(false);
            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'EXAM_ENABLED_NOT_DELETED') {
                addNotification('Exam enabled for master.', 'warning');
                setSelectedMasterId(null);
                setDeleteMasterToggler(false);
            }
            console.log('There is an error while deleting master', error);
        }
    }
    const [deleteCandidateModalToggler, setDeleteCandidateModalToggler] = useState(false);
    const handleDeleteCandidates = async (masterIndex) => {
        const master = masters[masterIndex];
        if (master?.id) {
            setSelectedMasterId(master.id);
            setDeleteCandidateModalToggler(true);
        }
    }
    const onConfirmDeleteCandidate = async () => {
        if (!selectedMasterId) return;
        try {
            const res = await deleteAllCandidates(selectedMasterId);
            if (res.code === 'OK') {
                setMasters(
                    masters.map((master) => {
                        if (master.id === selectedMasterId) {
                            master.candidateCount = 0;
                        }
                        return master;
                    })
                );
                setSelectedMasterId(null);
                setDeleteCandidateModalToggler(false);

            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'EXAM_ENABLED_NOT_DELETED') {
                addNotification('Exam enabled for this master.', 'warning');
                setSelectedMasterId(null);
                setDeleteCandidateModalToggler(false);
            }
            console.log('There is an error while deleting master candidates', error);
        }
    }
    const handleDownloadData = async (masterIndex) => {
        const master = masters[masterIndex];
        if (master) {
            if (master.candidateCount) {
                try {
                    const response = await getCandidateDataCsv(master.id);
                    const url = window.URL.createObjectURL(response.data);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `candidate-data.xlsx`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    addNotification('File downloaded successfully.', 'success');
                } catch (error) {
                    console.log('There is an error while downloading master data', error);
                }
            } else {
                addNotification('Candidate data not available.');
            }
        }
    }
    const onEditMaster = (pm) => {
        if (pm?.id) {
            if (pm.isLocked) {
                addNotification('The Content of this master is locked so it can not be edited.');
            } else {
                navigate(`${location.pathname}/master-details`, {
                    state: { masterId: pm.id }
                });
            }
        }
    }
    const onUploadCandidate = (pm) => {
        if (pm?.id) {
            setCandidateUploadModalData({
                isVisible: true,
                projectMasterId: pm.id
            })
        }
        // if (pm?.id) {
        //     if (pm.isLocked) {
        //         addNotification('The Content of this master is locked so it can not be edited.');
        //     } else {
        //         navigate(`${location.pathname}/master-details`, {
        //             state: { masterId: pm.id }
        //         });
        //     }
        // }
    }
    const onSearch = (e) => {
        setSearch(e.target.value);
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setCurrentPage(1);
            setNewSearch(!newSearch);
        }, 400);
    }
    const renderTableCell = (columnId, rowIndex) => {
        const row = masters[rowIndex];
        switch (columnId) {
            case 'driveName':
                return row['drive']['driveName']
            case 'course':
                return row['course']['courseName']
            case 'batch':
                return row['batch']['batchName']
            case 'examDate':
                return dayjs(row['examDate']).format('DD-MM-YYYY')
            case 'candidateCount':
                return <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        px: 2,
                        py: 1.2,
                        border: '1px solid',
                        borderColor: 'grey.400',
                        borderRadius: 10,
                        bgcolor: 'grey.200',
                        width: 'max-content'
                    }}>
                        {row['candidateCount']}
                        <Box sx={{
                            cursor: 'pointer',
                            height: 24,
                            width: 24
                        }} onClick={() => handleDeleteCandidates(rowIndex)}>
                            <img src={CHIP_CLOSE} alt='' />
                        </Box>
                    </Box>
                    <Box component='span' sx={{
                        mx: 1,
                        cursor: 'pointer'
                    }} onClick={() => onUploadCandidate(row)}>
                        <img src={UPLOAD} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'primary.main'
                        }}>Upload</Typography>
                    </Box>
                </Box>
            case 'actions':
                return <>
                    <Box component='span' sx={{
                        mx: 1,
                        cursor: 'pointer'
                    }} onClick={() => onEditMaster(row)}>
                        <img src={ACTION_EDIT} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'success.main'
                        }}>Edit</Typography>
                    </Box>
                    <Box component='span' sx={{
                        mx: 1,
                        cursor: 'pointer'
                    }} onClick={() => handelDeleteMaster(rowIndex)}>
                        <img src={ACTION_DELETE} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'error.main'
                        }}>Delete</Typography>
                    </Box>
                </>
            case 'status':
                return <Box component='span' sx={{
                    mx: 1,
                    cursor: 'pointer'
                }} onClick={() => handleDownloadData(rowIndex)}>
                    <img src={ACTION_DOWNLOAD} alt='' />
                    <Typography variant='body1' sx={{
                        display: 'inline-block',
                        verticalAlign: 'super',
                        ml: 1,
                        color: 'primary.main'
                    }}>Download Data</Typography>
                </Box>
            default:
                return row[columnId];
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400 }}>View Project</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Masters List</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <h1 className='title'>
                    Masters List
                </h1>

                <div className="search-header-wrapper">
                    <div className='search-wrapper'>
                        <input type='text' className='search-box' value={search} onChange={onSearch} placeholder='Search...' />
                        <img className='search-icon' src={SEARCH_ICON} alt='' />
                    </div>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary"
                        type="submit"
                        variant='contained'
                        startIcon={<img src={ADD_USER} alt='' />}
                        onClick={onAddMasterUser}
                        size='large'
                    >
                        Add Master
                    </RadiusButton>
                </div>

                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                            key={column.id}
                                            align='center'
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!masters?.length
                                    ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                        <TableCell colSpan={7} sx={{ border: 'none', textAlign: 'center' }}>
                                            No records available
                                        </TableCell>
                                    </TableRow>
                                    : masters.map((row, rowIndex) => {
                                        return (
                                            <TableRow hover role="checkbox" key={rowIndex}>
                                                {columns.map((column, index) => {
                                                    return <TableCell key={column.id} align='center' sx={{
                                                        px: 1.5,
                                                        py: 0.75
                                                    }}>
                                                        {renderTableCell(column.id, rowIndex)}
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination sx={{ mt: 'auto' }} count={maxPages} page={currentPage} onChange={handlePageChange} shape="rounded" />


                <Modal open={deleteMasterToggler} onClose={() => setDeleteMasterToggler(false)}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.base',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 9,
                    }}>
                        <Typography variant='h6' sx={{
                            fontWeight: 400,
                            mb: 8
                        }}>
                            Do you want to delete this master?
                        </Typography>

                        <div className='actions-container' style={{ justifyContent: 'center', gap: '40px' }}>
                            <RadiusButton variant='outlined' onClick={() => setDeleteMasterToggler(false)}>
                                No
                            </RadiusButton>
                            <RadiusButton variant='contained' onClick={onConfirmDeleteMaster}>
                                Yes
                            </RadiusButton>
                        </div>
                    </Box>
                </Modal>

                <Modal open={deleteCandidateModalToggler} onClose={() => setDeleteCandidateModalToggler(false)}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.base',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 9,
                    }}>
                        <Typography variant='h6' sx={{
                            fontWeight: 400,
                            mb: 8
                        }}>
                            Do you want to delete all candidates of this master?
                        </Typography>

                        <div className='actions-container' style={{ justifyContent: 'center', gap: '40px' }}>
                            <RadiusButton variant='outlined' onClick={() => setDeleteCandidateModalToggler(false)}>
                                No
                            </RadiusButton>
                            <RadiusButton variant='contained' onClick={() => onConfirmDeleteCandidate()}>
                                Yes
                            </RadiusButton>
                        </div>
                    </Box>
                </Modal>
                <CandidateUploadModal modalData={candidateUploadModalData} setModalData={setCandidateUploadModalData} dataUpdated={dataUpdated} setDataUpdated={setDataUpdated}></CandidateUploadModal>

            </Container>
        </MainContainer >
    );
}
