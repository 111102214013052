import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TimeField } from '@mui/x-date-pickers';
import { Breadcrumbs, Typography, Box, FormControl, Select, MenuItem, Paper, FormControlLabel, RadioGroup, Radio, IconButton, InputBase, Divider, InputLabel, TableContainer, TableBody, TableRow, TableCell, Table, TableHead, FormHelperText, OutlinedInput, TextField } from '@mui/material';
import dayjs from '../../../../config/dayjs';

import { useToaster } from '../../../../providers/ToasterProvider';
import { listByDriveAndCourse } from '../../../../services/masterService';
import { create, getDetailsWithQuestionsStatusById, updateById } from '../../../../services/configurationService';
import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';
import languages from '../../../../constant/languages';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import RadiusButton from '../../../../components/RadiusButton';
import TRUE_BLACK from '../../../../assets/images/true-black.svg';

import './ContentConfiguration.scss';

export const ContentConfiguration = () => {

    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const projectName = params.projectName;
    const { addNotification } = useToaster();

    const [configurationDetails, setConfigurationDetails] = useState(null);
    const [drive, setDrive] = useState({
        id: null,
        name: 'Drive name'
    });
    const [course, setCourse] = useState({
        id: null,
        name: 'Course name'
    });

    const [isLocked, setIsLocked] = useState(false);
    const [contentUploaded, setContentUploaded] = useState(false);
    const [isExamEnabled, setIsExamEnabled] = useState(false);
    const [configuration, setConfiguration] = useState({
        timeDuration: dayjs.utc().hour(0).minute(0).second(0).millisecond(0),
        timeDurationForScribe: dayjs.utc().hour(0).minute(0).second(0).millisecond(0),
        examTimes: [],
        unlockWithPassword: 'No',
        autoLogout: 'No',
        calculator: 'No',
        logarithm: 'No',
        calculatorType: 'normal',
        feedback: 'No',
        numberOfFeedbackQuestions: 1,
        sectionWise: 'No',
        sectionCount: 1,
        sections: [{
            sectionTitle: 'Section 1',
            sectionTime: dayjs.utc().hour(0).minute(0).second(0).millisecond(0)
        }],
        contentUploadMethod: 'set',
        numberOfEasyQuestions: 0,
        numberOfMediumQuestions: 0,
        numberOfHardQuestions: 0,
        numberOfSets: 1,
        questionsPerSet: 1,
        isBilingual: 'No',
        languages: [],
        instructionSheets: 'No',
        biometricRequired: 'No'
    });
    const [sectionCount, setSectionCount] = useState(1);
    const [languagesError, setLanguagesError] = useState(null);
    const [errors, setErrors] = useState({
        timeDuration: null,
        timeDurationForScribe: null,
        examTimes: [],
        sections: [{
            timeError: null,
            titleError: null,
        }],
        sectionError: null,
        numberOfSets: null,
        numberOfQuestions: null,
        lowQuestions: null,
    });

    useEffect(() => {
        const driveDetails = location?.state?.drive;
        const courseDetails = location?.state?.course;
        const configurationId = location?.state?.configurationId;
        if (!driveDetails || !courseDetails) {
            navigate(`/${projectName}/content-dashboard`)
        } else {
            setDrive(() => driveDetails);
            setCourse(() => courseDetails);
            const getBatches = async () => {
                try {
                    const res = await listByDriveAndCourse(driveDetails.id, courseDetails.id);
                    if (res.code === 'OK') {
                        const batches = res.data.list.map((pm) => {
                            return {
                                projectMasterId: pm.id,
                                batchId: pm.batchId,
                                examDate: dayjs.utc(pm.examDate),
                                batchName: pm['batch.batchName'],
                                startTime: dayjs.utc(pm.examDate).hour(0).minute(0).second(0).millisecond(0)
                            }
                        })
                        if (configurationId) {
                            const fetchDetails = async () => {
                                try {
                                    const res = await getDetailsWithQuestionsStatusById(configurationId);
                                    if (res.code === 'OK') {
                                        const data = res.data;
                                        setIsLocked(data.isLocked);
                                        setContentUploaded(data.contentUploaded);
                                        setIsExamEnabled(data.examEnabled);
                                        const dataToSet = {
                                            timeDuration: dayjs.utc().hour(data.timeDuration.split(':')[0]).minute(data.timeDuration.split(':')[1]).second(0).millisecond(0),
                                            timeDurationForScribe: dayjs.utc().hour(data.timeDurationForScribe.split(':')[0]).minute(data.timeDurationForScribe.split(':')[1]).second(0).millisecond(0),
                                            examTimes: [],
                                            unlockWithPassword: data.unlockWithPassword ? 'Yes' : 'No',
                                            autoLogout: data.autoLogout ? 'Yes' : 'No',
                                            calculator: data.calculator === 'none' ? 'No' : 'Yes',
                                            calculatorType: data.calculator === 'scientific' ? 'scientific' : 'normal',
                                            logarithm: data.logarithm ? 'Yes' : 'No',
                                            feedback: data.feedback ? 'Yes' : 'No',
                                            numberOfFeedbackQuestions: data.numberOfFeedbackQuestions ? data.numberOfFeedbackQuestions : 1,
                                            sectionWise: data.sectionWise ? 'Yes' : 'No',
                                            languages: data.languages,
                                            contentUploadMethod: data.contentUploadMethod,
                                            numberOfEasyQuestions: data.contentUploadMethod === 'bulk' ? data.numberOfEasyQuestions : 0,
                                            numberOfMediumQuestions: data.contentUploadMethod === 'bulk' ? data.numberOfMediumQuestions : 0,
                                            numberOfHardQuestions: data.contentUploadMethod === 'bulk' ? data.numberOfHardQuestions : 0,
                                            numberOfSets: data.numberOfSets,
                                            questionsPerSet: data.questionsPerSet,
                                            isBilingual: data.isBilingual ? 'Yes' : 'No',
                                            instructionSheets: data.instructionSheets ? 'Yes' : 'No',
                                            biometricRequired: data.biometricRequired ? 'Yes' : 'No',
                                        }

                                        for (let batch of batches) {
                                            const existingBatch = data.examTimes.findIndex((examTime) => examTime.projectMasterId === batch.projectMasterId);
                                            let examTime = {
                                                projectMasterId: batch.projectMasterId,
                                                batchId: batch.batchId,
                                                batchName: batch.batchName,
                                                examDate: batch.examDate
                                            }
                                            if (existingBatch > -1) {
                                                examTime.startTime = dayjs.utc(data.examTimes[existingBatch].startTime);
                                            } else {
                                                examTime.startTime = dayjs.utc(batch.examDate).hour(0).minute(0).second(0).millisecond(0)
                                            }
                                            dataToSet.examTimes.push(examTime)
                                        }

                                        if (data.sectionWise) {
                                            setSectionCount(data.sectionCount);
                                            dataToSet.sectionCount = data.sectionCount;
                                            dataToSet.sections = data.sections.map((section) => {
                                                return {
                                                    ...section,
                                                    sectionTime: dayjs.utc().hour(section.sectionTime.split(':')[0]).minute(section.sectionTime.split(':')[1]).second(0).millisecond(0),
                                                }
                                            });
                                        }
                                        setConfiguration({
                                            ...configuration,
                                            ...dataToSet
                                        });
                                        setErrors({
                                            ...errors,
                                            examTimes: batches.map(() => null),
                                            sections: data.sectionWise ? dataToSet.sections.map((s) => ({ timeError: null, titleError: null })) : errors.sections
                                        });
                                        setConfigurationDetails(data);
                                    }
                                } catch (error) {
                                    console.log('There is an error while fetching content configuration details', error);
                                }
                            }
                            fetchDetails();
                        } else {
                            setConfiguration({
                                ...configuration,
                                examTimes: batches,
                            });
                            setErrors({
                                ...errors,
                                examTimes: batches.map(() => null)
                            });
                        }
                    }
                } catch (error) {
                    console.log('There is an error while getting batches', error);
                }
            }
            getBatches();
        }
    }, [location, projectName, navigate]);

    const handleTimeDurationChange = (value, err) => {
        let error = err.validationError ? 'Please enter valid time.' : null;
        if (!value) {
            error = 'Please enter valid time.'
        }
        if (value && value.hour() === 0 && value.minute() === 0) {
            error = 'Please enter valid time.'
        }
        const scribeError = getScribeError(value, configuration.timeDurationForScribe);
        setErrors({
            ...errors,
            timeDuration: error,
            timeDurationForScribe: scribeError,
        });
        if (value) {
            setConfiguration({
                ...configuration,
                timeDuration: value
            });
        }
    }
    const handleTimeDurationForScribeChange = (value, err) => {
        let error = err.validationError ? 'Please enter valid time.' : null;
        if (!value) {
            error = 'Please enter valid time.'
        }
        if (value && value.hour() === 0 && value.minute() === 0) {
            error = 'Please enter valid time.'
        }
        const scribeError = getScribeError(configuration.timeDuration, value);
        setErrors({
            ...errors,
            timeDurationForScribe: scribeError ?? error,
        });
        if (value) {
            setConfiguration({
                ...configuration,
                timeDurationForScribe: value
            });
        }
    }
    const handleExamTimesChange = (value, err, index) => {
        let error = err.validationError ? 'Please enter valid time.' : null;
        if (!value) {
            error = 'Please enter valid time.'
        }
        if (value && value.hour() === 0 && value.minute() === 0) {
            error = 'Please enter valid time.'
        }
        setErrors({
            ...errors,
            examTimes: errors.examTimes.map((examTimeErr, i) => {
                if (i === index) {
                    return error;
                }
                return examTimeErr;
            })
        });
        if (value) {
            setConfiguration({
                ...configuration,
                examTimes: configuration.examTimes.map((examTime, i) => {
                    if (i === index) {
                        examTime.startTime = value;
                    }
                    return examTime;
                })
            });
        }
    }
    const getScribeError = (duration, scribeDuration) => {
        if (duration === null || scribeDuration === null) {
            return null
        };
        if (scribeDuration.isBefore(duration)) {
            return 'Scribe candidate must have at least same duration as regular candidate.';
        }
        return null;
    }
    // const getEndTimeErrors = (duration, startTime, endTime) => {
    //     if (duration === null || startTime === null || endTime === null) {
    //         return null
    //     };
    //     const minutes = (duration.hour() * 60) + duration.minute();
    //     if (!minutes) {
    //         return null;
    //     };
    //     const calculatedEndTime = dayjs(startTime).add(minutes, 'minutes');
    //     if ((calculatedEndTime.hour() !== endTime.hour()) || (calculatedEndTime.minute() !== endTime.minute())) {
    //         return 'Time duration does not match with exam start time and exam end time.'
    //     }
    //     return null;
    // }
    const handleRadioChange = (value, fieldName) => {
        setConfiguration({
            ...configuration,
            [fieldName]: value
        });
    }
    const handleSectionWiseChange = (value) => {
        if (value === 'No') {
            setErrors({
                ...errors,
                sectionError: null,
                sections: [{ timeError: null, titleError: null }],
                lowQuestions: null,
            });
        }
        setSectionCount(1);
        setConfiguration({
            ...configuration,
            sectionWise: value,
            sectionCount: 1,
            contentUploadMethod: value === 'No' ? configuration.contentUploadMethod : 'set',
            sections: [{
                sectionTime: dayjs.utc().hour(0).minute(0).second(0).millisecond(0),
                sectionTitle: 'Section 1'
            }]
        });
    }
    const handleSectionInputCheck = () => {
        let sections = configuration.sections;
        let sectionsErrors = errors.sections;
        if (configuration.sectionCount !== sectionCount) {
            if (sectionCount > configuration.sectionCount) {
                for (let z = configuration.sectionCount + 1; z <= sectionCount; z++) {
                    sections.push({
                        sectionTitle: `Section ${z}`,
                        sectionTime: dayjs.utc().hour(0).minute(0).second(0).millisecond(0),
                    })
                    sectionsErrors.push({
                        timeError: null,
                        titleError: null,
                    });
                }
                setErrors({
                    ...errors,
                    sections: sectionsErrors
                })
            } else {
                sections.splice(sectionCount, configuration.sectionCount - sectionCount);
                sectionsErrors.splice(sectionCount, configuration.sectionCount - sectionCount);
                setErrors({
                    ...errors,
                    sections: sectionsErrors
                });
            }
        }
        setConfiguration({
            ...configuration,
            sectionCount: sectionCount,
            sections: sections,
        });
    }
    const handleSectionTitleChange = (event, index) => {
        const value = event.target.value;
        let error = null;
        if (!value) {
            error = 'Please enter valid section title.';
        } else if (!/^([a-zA-Z0-9 _-]+)$/.test(value)) {
            error = 'Section title must be alpha numeric.'
        }

        const newSections = configuration.sections.map((section, i) => {
            if (index === i) {
                section.sectionTitle = value;
            }
            return section;
        });
        const sectionErrors = errors.sections.map((sectionErr, i) => {
            if (index === i) {
                return {
                    ...sectionErr,
                    titleError: error
                }
            }
            return sectionErr;
        });
        setConfiguration({
            ...configuration,
            sections: newSections
        });
        setErrors({
            ...errors,
            sections: sectionErrors
        });
    }
    const handleSectionTimeChange = (value, err, index) => {
        let error = err.validationError ? 'Please enter valid time.' : null;
        if (!value) {
            error = 'Please enter valid time.'
        }
        if (value && value.hour() === 0 && value.minute() === 0) {
            error = 'Please enter valid time.'
        }


        if (error) {
            const sectionErrors = errors.sections.map((sectionErr, i) => {
                if (index === i) {
                    return {
                        ...sectionErr,
                        timeError: error
                    };
                }
                return sectionErr;
            });
            setErrors({
                ...errors,
                sections: sectionErrors,
                sectionError: null,
            });
            return;
        };

        if (value) {
            const newSections = configuration.sections.map((section, i) => {
                if (index === i) {
                    section.sectionTime = value;
                }
                return section;
            });
            const sectionError = getSectionsTimeError(newSections, configuration.timeDuration);
            if (typeof sectionError === 'number') {
                let sectionsErrors = errors.sections.map((sectionErr, i) => {
                    if (i === sectionError) {
                        return {
                            ...sectionErr,
                            timeError: 'Please enter valid time.'
                        }
                    }
                    if (i === index) {
                        return {
                            ...sectionErr,
                            timeError: null
                        }
                    }
                    return sectionErr;
                });
                setErrors({
                    ...errors,
                    sections: sectionsErrors,
                });
            } else {
                setErrors({
                    ...errors,
                    sections: errors.sections.map(() => ({ timeError: null, titleError: null })),
                    sectionError: sectionError
                });
            }
            setConfiguration({
                ...configuration,
                sections: newSections
            });
        }
    }
    const getSectionsTimeError = (sections, duration) => {
        if (duration === null) return null;
        for (let z = 0; z < sections.length; z++) {
            let section = sections[z];
            if (section.sectionTime.hour() === 0 && section.sectionTime.minute() === 0) {
                return z;
            }
        }

        const totalMinutes = (duration.hour() * 60) + duration.minute();
        let minutes = 0;
        for (let section of sections) {
            minutes += (section.sectionTime.hour() * 60) + section.sectionTime.minute();
        }
        if (totalMinutes !== minutes) {
            return 'Total time for sections must match with exam time duration.';
        }
        return null;
    }
    const handleNumberOfFeedbackQuestionsChange = (e) => {
        if (!e.target.value) return;
        let count = Number(e.target.value);
        if (isNaN(count)) return;
        if (count < 1) {
            count = 1;
        }
        setConfiguration({
            ...configuration,
            numberOfFeedbackQuestions: count
        });
    }
    const handleCountChange = (e) => {
        if (!e.target.value) return;
        let count = Number(e.target.value);
        if (isNaN(count)) return;
        if (count < 1) {
            count = 1;
        }
        if (count > 12) {
            count = 12;
        }

        setSectionCount(count);
    }
    const handleContentUploadMethodChange = (e, value) => {
        setConfiguration({
            ...configuration,
            contentUploadMethod: value,
        });
    }
    const handleNumberOfSetsChange = (e) => {
        let value = e.target.value;
        if (value < 1) {
            value = 1;
        }
        if (value > 50) {
            value = 50;
        }
        if (value < configuration.examTimes.length) {
            setErrors({
                ...errors,
                numberOfSets: 'Number of sets must be same or more than available batches.'
            });
        } else {
            setErrors({
                ...errors,
                numberOfSets: null
            });
        }
        setConfiguration({
            ...configuration,
            numberOfSets: value,
        });
    }
    const handleNumberOfQuestionsChange = (e, field) => {
        let value = Number(e.target.value);
        if (value < 0 || isNaN(value)) {
            value = 0;
        }
        const error = getNumberOfQuestionsError(value, field);

        setErrors({
            ...errors,
            numberOfQuestions: error
        });
        setConfiguration({
            ...configuration,
            [field]: value,
        });
    }
    const getNumberOfQuestionsError = (value, field) => {
        let totalQuestions = 0;
        if (field === 'questionsPerSet') {
            totalQuestions = configuration.numberOfEasyQuestions + configuration.numberOfMediumQuestions + configuration.numberOfHardQuestions;
        } else if (field === 'numberOfEasyQuestions') {
            totalQuestions = value + configuration.numberOfMediumQuestions + configuration.numberOfHardQuestions;
        } else if (field === 'numberOfMediumQuestions') {
            totalQuestions = configuration.numberOfEasyQuestions + value + configuration.numberOfHardQuestions;
        } else {
            totalQuestions = configuration.numberOfEasyQuestions + configuration.numberOfMediumQuestions + value;
        }
        const questionsPerSet = field === 'questionsPerSet' ? value : configuration.questionsPerSet;
        if (questionsPerSet !== totalQuestions) {
            return 'Number of questions must be same as questions per set.'
        }
        return null;
    }
    const getLowQuestionsError = (value) => {
        if (configuration.sectionWise === 'Yes') {
            if (value < configuration.sections.length) {
                return 'Number of questions must be more than number of sections';
            }
        }
        return null;
    }
    const handleQuestionsPerSetChange = (e) => {
        let value = Number(e.target.value);
        if (value < 1 || isNaN(value)) {
            value = 1;
        }
        if (value > 100) {
            value = 100;
        }
        const numberOfQuestionsError = getNumberOfQuestionsError(value, 'questionsPerSet')
        const lowQuestionsError = getLowQuestionsError(value);
        setErrors({
            ...errors,
            numberOfQuestions: numberOfQuestionsError,
            lowQuestions: lowQuestionsError,
        })
        setConfiguration({
            ...configuration,
            questionsPerSet: value,
        });
    }
    const handleInstructionSheetChange = (e, value) => {
        setConfiguration({
            ...configuration,
            instructionSheets: value,
        });
    }
    const handleIsBilingualChange = (e, value) => {
        setConfiguration({
            ...configuration,
            isBilingual: value,
            languages: [],
        });
    }
    const handleBilingualLanguagesChange = (e) => {
        if (!e.target.value.length) {
            setLanguagesError(true);
        } else {
            setLanguagesError(null);
        }
        setConfiguration({
            ...configuration,
            languages: e.target.value
        })
    }
    const handleBilingualLanguageChange = (e) => {
        if (!e.target.value) {
            setLanguagesError(true);
        } else {
            setLanguagesError(null);
        }
        setConfiguration({
            ...configuration,
            languages: [e.target.value]
        });
    }

    const getExamTimesError = () => {
        let errorIndex = null;
        for (let i = 0; i < configuration.examTimes.length; i++) {
            const examTime = configuration.examTimes[i];
            if (examTime.startTime.hour() === 0 && examTime.startTime.minute() === 0) {
                errorIndex = i;
                break;
            }
        }
        return errorIndex;
    }

    const handleSubmitForm = async () => {
        if (!drive?.id || !course?.id) return;
        if (configuration.timeDuration.hour() === 0 && configuration.timeDuration.minute() === 0) {
            setErrors({
                ...errors,
                timeDuration: 'Please enter valid time.'
            });
            return;
        }
        if (errors.timeDuration || errors.timeDurationForScribe || errors.numberOfSets) return;
        const examTimesError = getExamTimesError();
        if (examTimesError !== null) {
            setErrors({
                ...errors,
                examTimes: errors.examTimes.map((examTimeErr, index) => {
                    if (index === examTimesError) {
                        return 'Please enter valid time';
                    }
                    return examTimeErr;
                })
            })
            return;
        }
        for (let examTimeErr of errors.examTimes) {
            if (examTimeErr) return;
        }
        if (errors.sectionError) return;
        for (let sectionErr of errors.sections) {
            if (sectionErr.timeError || sectionErr.titleError) return;
        }
        if (configuration.numberOfSets < configuration.examTimes.length) {
            setErrors({
                ...errors,
                numberOfSets: 'Number of sets must be same or more than available batches.'
            })
            return;
        }
        if (configuration.contentUploadMethod === 'bulk') {
            const error = getNumberOfQuestionsError(configuration.questionsPerSet, 'questionsPerSet');
            if (error) {
                setErrors({
                    ...errors,
                    numberOfQuestions: error
                })
                return;
            }
        }
        if (configuration.sectionWise === 'Yes') {
            if (errors.lowQuestions) return;
            const lowQuestionsError = getLowQuestionsError(configuration.questionsPerSet);
            if (lowQuestionsError) {
                setErrors({
                    ...errors,
                    lowQuestions: lowQuestionsError
                })
                return;
            }
            let sectionError = getSectionsTimeError(configuration.sections, configuration.timeDuration)
            if (typeof sectionError === 'number') {
                const sectionsErrors = errors.sections.map((sectionErr, i) => {
                    if (sectionError === i) {
                        return {
                            ...sectionErr,
                            timeError: 'Please enter valid time.',
                        }
                    }
                    return sectionErr;
                });
                setErrors({
                    ...errors,
                    sections: sectionsErrors
                });
            } else {
                setErrors({
                    ...errors,
                    sectionError: sectionError
                });
            }
            if (sectionError) return;

            let sectionTitleError = null;
            const sectionErrors = [];
            for (let i = 0; i < errors.sections; i++) {
                const value = configuration.sections[i].sectionTitle;
                const sectionErr = {
                    timeError: null,
                    titleError: null,
                }
                if (!value) {
                    sectionErr.titleError = 'Please enter valid section title.';
                    sectionTitleError = true;
                } else if (!/^([a-zA-Z0-9 _-]+)$/.test(value)) {
                    sectionErr.titleError = 'Section title must be alpha numeric.'
                    sectionTitleError = true;
                }
                sectionErrors.push(sectionErr);
            }
            if (sectionTitleError) return;
        }
        if (!configuration.languages.length) {
            setLanguagesError(true);
            return;
        };

        const body = {
            driveId: drive.id,
            courseId: course.id,
            timeDuration: configuration.timeDuration.format('HH:mm'),
            timeDurationForScribe: configuration.timeDurationForScribe.format('HH:mm'),
            examTimes: configuration.examTimes.map((examTime) => {
                const startTime = dayjs.utc(examTime.examDate).hour(examTime.startTime.hour()).minute(examTime.startTime.minute());
                return {
                    projectMasterId: examTime.projectMasterId,
                    batchId: examTime.batchId,
                    startTime: startTime.format(),
                }
            }),
            unlockWithPassword: configuration.unlockWithPassword === 'No' ? false : true,
            autoLogout: configuration.autoLogout === 'No' ? false : true,
            calculator: configuration.calculator === 'No' ? 'none' : configuration.calculatorType === 'normal' ? 'normal' : 'scientific',
            logarithm: configuration.logarithm === 'No' ? false : true,
            feedback: configuration.feedback === 'No' ? false : true,
            numberOfFeedbackQuestions: 0,
            sectionWise: configuration.sectionWise === 'No' ? false : true,
            languages: configuration.languages,
            contentUploadMethod: configuration.contentUploadMethod,
            numberOfEasyQuestions: configuration.numberOfEasyQuestions,
            numberOfMediumQuestions: configuration.numberOfMediumQuestions,
            numberOfHardQuestions: configuration.numberOfHardQuestions,
            numberOfSets: configuration.numberOfSets,
            questionsPerSet: configuration.questionsPerSet,
            isBilingual: configuration.isBilingual === 'No' ? false : true,
            instructionSheets: configuration.instructionSheets === 'No' ? false : true,
            biometricRequired: configuration.biometricRequired === 'No' ? false : true
        }
        if (body.feedback) {
            body.numberOfFeedbackQuestions = configuration.numberOfFeedbackQuestions
        }
        if (body.sectionWise) {
            body.sectionCount = configuration.sectionCount;
            body.sections = configuration.sections.map((section) => {
                return {
                    sectionTime: section.sectionTime.format('HH:mm'),
                    sectionTitle: section.sectionTitle?.trim()
                }
            });
        }

        if (configurationDetails) {
            try {
                const res = await updateById(configurationDetails.id, body)
                if (res.code === 'OK') {
                    addNotification('Configuration updated successfully.', 'success');
                    goToContentDashboard();
                }
            } catch (error) {
                addNotification('Configuration does not updated.', 'danger');
                console.log('There is an error while updating configuration details', error);
            }
        } else {
            try {
                const res = await create(body);
                if (res.code === 'OK') {
                    addNotification('Configuration added successfully.', 'success')
                    goToContentDashboard();
                }
            } catch (error) {
                const responseData = error?.response?.data?.data;
                if (responseData?.status === 422 && responseData?.code === 'UNPROCESSABLE_ENTITY') {
                    addNotification('Configuration already exists.', 'danger');
                    console.log('There is an error while creating configuration', error);
                    return;
                }
                addNotification('Configuration does not created.', 'danger');
                console.log('There is an error while creating configuration', error);
            }
        }
    }

    const goToContentDashboard = () => {
        navigate(`/${projectName}/content-dashboard`);
    }

    return (
        <MainContainer>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400, textTransform: 'capitalize' }}>{projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Content Configuration</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: 'calc(100vh - 116px)'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        columnGap: 2,
                        rowGap: 0.5,
                        mb: 3
                    }}>
                        <TextField
                            label="Drive Name"
                            variant="outlined"
                            value={drive?.['name']}
                            disabled
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={{
                                width: 238,
                                bgcolor: 'background.base'
                            }}
                        />
                        <TextField
                            label="Course Name"
                            variant="outlined"
                            value={course?.['name']}
                            disabled
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={{
                                width: 238,
                                bgcolor: 'background.base'
                            }}
                        />
                        {!isExamEnabled && <RadiusButton sx={{
                            fontWeight: 500,
                            ml: 'auto',
                            textTransform: 'capitalize'
                        }} color="primary"
                            variant='contained'
                            size='large'
                            onClick={handleSubmitForm}
                        >Save</RadiusButton>}
                    </Box>

                    <Paper sx={{
                        borderRadius: 1,
                        bgcolor: 'background.base',
                        flex: 1,
                        maxWidth: 'calc(100vw - 140px)',
                        overflow: 'auto',
                    }}>
                        <TableContainer sx={{
                            p: 2,
                            maxHeight: 'inherit',
                            maxWidth: 'inherit',
                            // overflow: 'auto',
                            borderRadius: 1
                        }}>
                            <Table stickyHeader aria-label="table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align='left'
                                            style={{ minWidth: 320 }}
                                            sx={{ border: 'none', p: 0 }}
                                        ></TableCell>
                                        <TableCell
                                            align='left'
                                            style={{ minWidth: 320 }}
                                            sx={{ border: 'none', p: 0 }}
                                        ></TableCell>
                                        <TableCell
                                            align='right'
                                            style={{ minWidth: 320 }}
                                            sx={{ border: 'none', p: 0 }}
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Time Duration
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TimeField
                                                className='time-input'
                                                defaultValue={configuration.timeDuration}
                                                value={configuration.timeDuration}
                                                format="HH:mm"
                                                disabled={isExamEnabled ? true : false}
                                                onChange={(d, e) => handleTimeDurationChange(d, e)}
                                                error={!!errors.timeDuration}
                                                slotProps={{
                                                    textField: {
                                                        helperText: errors.timeDuration,
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {`${configuration.timeDuration.format('HH')} Hours ${configuration.timeDuration.format('mm')} Minutes`}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Time Duration for scribe candidate
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TimeField
                                                className='time-input'
                                                defaultValue={configuration.timeDurationForScribe}
                                                value={configuration.timeDurationForScribe}
                                                format="HH:mm"
                                                disabled={isExamEnabled ? true : false}
                                                error={!!errors.timeDurationForScribe}
                                                onChange={handleTimeDurationForScribeChange}
                                                slotProps={{
                                                    textField: {
                                                        helperText: errors.timeDurationForScribe,
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {`${configuration.timeDurationForScribe.format('HH')} Hours ${configuration.timeDurationForScribe.format('mm')} Minutes`}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    {
                                        configuration.examTimes.map((examTime, index) => {
                                            return <TableRow key={index}>
                                                <TableCell sx={{ pt: index ? 0 : '', border: (index === configuration.examTimes.length - 1) ? '' : 'none', verticalAlign: 'top' }}>
                                                    {index === 0 && <Typography component='span' variant='subtitle1'>
                                                        Exam Times
                                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                                    </Typography>}
                                                </TableCell>
                                                <TableCell sx={{ pt: index ? 0 : '', border: (index === configuration.examTimes.length - 1) ? '' : 'none' }}>
                                                    <Typography component='div' variant='subtitle1'>
                                                        {`${examTime.batchName} (${examTime.examDate.format('DD-MM-YYYY')})`}
                                                    </Typography>
                                                    <TimeField
                                                        className='time-input'
                                                        defaultValue={examTime.startTime}
                                                        value={examTime.startTime}
                                                        disabled={isExamEnabled ? true : false}
                                                        format="HH:mm"
                                                        onChange={(v, e) => handleExamTimesChange(v, e, index)}
                                                        error={!!errors?.['examTimes']?.[index]}
                                                        slotProps={{
                                                            textField: {
                                                                helperText: errors?.['examTimes']?.[index],
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ pt: index ? 0 : '', border: (index === configuration.examTimes.length - 1) ? '' : 'none', textAlign: 'right', color: 'primary.main' }}>
                                                    <Typography component='span' variant='subtitle1'>
                                                        {`Starting time ${examTime.startTime.format('hh.mm a')}`}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        })
                                    }

                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Password key for unlock
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <RadioGroup row name="password-key-for-unlock" onChange={(e, value) => handleRadioChange(value, 'unlockWithPassword')}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.unlockWithPassword === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.unlockWithPassword === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.unlockWithPassword}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Auto logout
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <RadioGroup row name="password-key-for-unlock" onChange={(e, value) => handleRadioChange(value, 'autoLogout')}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.autoLogout === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.autoLogout === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.autoLogout}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Calculator
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <RadioGroup row name="password-key-for-unlock" onChange={(e, value) => handleRadioChange(value, 'calculator')}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.calculator === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.calculator === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                            {configuration.calculator === 'Yes' && (
                                                <RadioGroup row name="password-key-for-unlock" onChange={(e, value) => handleRadioChange(value, 'calculatorType')}>
                                                    <FormControlLabel sx={{ width: 150, textTransform: 'capitalize' }} value='scientific' control={
                                                        <Radio
                                                            disabled={isExamEnabled ? true : false}
                                                            checked={configuration.calculatorType === 'scientific'}
                                                        />
                                                    } label="scientific" />
                                                    <FormControlLabel sx={{ width: 150, textTransform: 'capitalize' }} value='normal' control={
                                                        <Radio
                                                            disabled={isExamEnabled ? true : false}
                                                            checked={configuration.calculatorType === 'normal'}
                                                        />
                                                    } label="normal" />
                                                </RadioGroup>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main' }}>
                                            <Typography component='span' variant='subtitle1' sx={{ textTransform: 'capitalize' }}>
                                                {
                                                    configuration.calculator === 'No'
                                                        ? configuration.calculator
                                                        : configuration.calculatorType
                                                }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Logarithm
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <RadioGroup row name="logarithm" onChange={(e, value) => handleRadioChange(value, 'logarithm')}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.logarithm === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.logarithm === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.logarithm}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ borderColor: configuration.feedback === 'No' ? '' : 'transparent !important' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                Feedback
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderColor: configuration.feedback === 'No' ? '' : 'transparent !important' }}>
                                            <RadioGroup row name="password-key-for-unlock" onChange={(e, value) => handleRadioChange(value, 'feedback')}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled || isLocked ? true : false}
                                                        checked={configuration.feedback === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled || isLocked ? true : false}
                                                        checked={configuration.feedback === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main', borderColor: configuration.feedback === 'No' ? '' : 'transparent !important' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.feedback}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {configuration.feedback === 'Yes' && (
                                        <TableRow>
                                            <TableCell sx={{ pt: 0 }}>
                                                <Typography component='span' variant='subtitle1'>
                                                    How many questions
                                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ pt: 0 }}>
                                                <InputBase
                                                    sx={{
                                                        py: 0.7,
                                                        px: 1,
                                                        width: 180,
                                                        borderRadius: 2,
                                                        border: '1px solid #A3A3A3!important'
                                                    }}
                                                    placeholder="Enter Count"
                                                    type='number'
                                                    disabled={isExamEnabled || isLocked ? true : false}
                                                    value={configuration.numberOfFeedbackQuestions}
                                                    onChange={handleNumberOfFeedbackQuestionsChange}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'right', color: 'primary.main', pt: 0 }}></TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell sx={{
                                            borderColor: configuration.sectionWise === 'Yes' ? 'transparent !important' : ''
                                        }}>
                                            <Typography component='span' variant='subtitle1'>
                                                Section wise
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{
                                            borderColor:
                                                configuration.sectionWise === 'Yes' ? 'transparent !important' : ''
                                        }}>
                                            <RadioGroup row name="password-key-for-unlock" onChange={(e, value) => handleSectionWiseChange(value)}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled || isLocked ? true : false}
                                                        checked={configuration.sectionWise === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled || isLocked ? true : false}
                                                        checked={configuration.sectionWise === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{
                                            textAlign: 'right',
                                            color: 'primary.main',
                                            borderColor: configuration.sectionWise === 'Yes' ? 'transparent !important' : ''
                                        }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.sectionWise}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {configuration.sectionWise === 'Yes' && (
                                        <TableRow>
                                            <TableCell sx={{ pt: 0, border: 'none' }}>
                                                <Typography component='span' variant='subtitle1'>
                                                    How many sections
                                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ pt: 0, border: 'none' }}>
                                                <Paper
                                                    component="form"
                                                    sx={{
                                                        display: 'flex', alignItems: 'center', width: 180, borderRadius: 2, boxShadow: 'none', border: '1px solid #A3A3A3!important'
                                                    }}
                                                >
                                                    <InputBase
                                                        sx={{ ml: 1, flex: 1 }}
                                                        placeholder="Enter Count"
                                                        type='number'
                                                        value={sectionCount}
                                                        disabled={isExamEnabled || isLocked ? true : false}
                                                        onChange={handleCountChange}
                                                    />
                                                    <Divider sx={{ height: 44, borderColor: '#A3A3A3' }} orientation="vertical" />
                                                    <IconButton color="primary" onClick={() => {
                                                        if (isLocked) return;
                                                        handleSectionInputCheck()
                                                    }} disableRipple>
                                                        <img src={TRUE_BLACK} alt='' />
                                                    </IconButton>
                                                </Paper>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'right', color: 'primary.main', pt: 0, border: 'none' }}>
                                                <Typography component='span' variant='subtitle1'>
                                                    {configuration.sectionWise}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {configuration.sectionWise === 'Yes' && (
                                        configuration.sections.map((section, index) => <TableRow key={index}>
                                            <TableCell sx={{
                                                pt: 0,
                                                borderColor: index === configuration.sections.length - 1 ? '' : 'transparent !important'
                                            }}>
                                                <Typography component='span' variant='subtitle1'>
                                                    {`Section ${index + 1}`}
                                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{
                                                pt: 0,
                                                borderColor: index === configuration.sections.length - 1 ? '' : 'transparent !important'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    gap: 1,
                                                }}>
                                                    <TextField
                                                        sx={{ width: 220 }}
                                                        size='small'
                                                        id="section-title"
                                                        label="Section Title"
                                                        variant="outlined"
                                                        value={configuration.sections[index].sectionTitle}
                                                        disabled={isExamEnabled || isLocked ? true : false}
                                                        onChange={(e) => handleSectionTitleChange(e, index)}
                                                    />
                                                    <TimeField
                                                        size='small'
                                                        sx={{ width: 80 }}
                                                        defaultValue={configuration.sections[index].sectionTime}
                                                        value={configuration.sections[index].sectionTime}
                                                        format="HH:mm"
                                                        disabled={isExamEnabled || isLocked ? true : false}
                                                        onChange={(d, e) => handleSectionTimeChange(d, e, index)}
                                                    />
                                                </Box>
                                                {
                                                    <>
                                                        {errors.sections[index]?.titleError && <FormHelperText sx={{ color: 'error.main', maxWidth: '100%' }}>
                                                            {errors.sections[index]?.titleError}
                                                        </FormHelperText>}
                                                        {errors.sections[index]?.timeError && <FormHelperText sx={{ color: 'error.main', maxWidth: '100%' }}>
                                                            {errors.sections[index]?.timeError}
                                                        </FormHelperText>}
                                                    </>
                                                }
                                                {
                                                    (configuration.sections.length - 1) === index && errors.sectionError && (
                                                        <FormHelperText sx={{ color: 'error.main', maxWidth: '100%' }}>
                                                            Total time for sections must match <br />
                                                            with exam time duration.
                                                        </FormHelperText>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell sx={{
                                                textAlign: 'right',
                                                color: 'primary.main',
                                                pt: 0,
                                                borderColor: index === configuration.sections.length - 1 ? '' : 'transparent !important'
                                            }}>
                                                <Typography component='span' variant='subtitle1'>
                                                    {`${configuration.sections?.[index]?.sectionTime?.format('HH')} Hours ${configuration.sections?.[index]?.sectionTime?.format('mm')} Minutes`}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>)
                                    )}
                                    <TableRow>
                                        <TableCell sx={{ borderColor: configuration.contentUploadMethod === 'bulk' ? 'transparent !important' : null }}>
                                            <Typography component='span' variant='subtitle1'>
                                                Content Upload Method
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderColor: configuration.contentUploadMethod === 'bulk' ? 'transparent !important' : null }}>
                                            <RadioGroup row name="instructionSheet" onChange={handleContentUploadMethodChange}>
                                                <FormControlLabel sx={{ width: 150 }} value='set' control={
                                                    <Radio
                                                        disabled={isExamEnabled || contentUploaded || isLocked ? true : false}
                                                        checked={configuration.contentUploadMethod === 'set'}
                                                    />
                                                } label="Multiple sets" />
                                                <FormControlLabel sx={{ width: 150 }} value='bulk' control={
                                                    <Radio
                                                        disabled={configuration.sectionWise === 'Yes' || isExamEnabled || contentUploaded || isLocked ? true : false}
                                                        checked={configuration.contentUploadMethod === 'bulk'}
                                                    />
                                                } label="Bulk upload" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{
                                            textAlign: 'right',
                                            color: 'primary.main',
                                            borderColor: configuration.contentUploadMethod === 'bulk' ? 'transparent !important' : null
                                        }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.contentUploadMethod === 'set' ? 'Muiltiple sets' : 'Bulk upload'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {configuration.contentUploadMethod === 'bulk' && <>
                                        <TableRow>
                                            <TableCell sx={{ borderColor: 'transparent !important' }}>
                                                <Typography component='span' variant='subtitle1'>
                                                    Number of easy questions
                                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderColor: 'transparent !important' }}>
                                                <InputBase
                                                    sx={{
                                                        py: 0.7,
                                                        px: 1,
                                                        width: 180,
                                                        borderRadius: 2,
                                                        border: '1px solid #A3A3A3!important'
                                                    }}
                                                    placeholder="Enter Count"
                                                    type='number'
                                                    disabled={isExamEnabled || isLocked ? true : false}
                                                    value={configuration.numberOfEasyQuestions}
                                                    onChange={(e) => handleNumberOfQuestionsChange(e, 'numberOfEasyQuestions')}
                                                />
                                            </TableCell>
                                            <TableCell sx={{
                                                borderColor: 'transparent !important'
                                            }}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderColor: 'transparent !important' }}>
                                                <Typography component='span' variant='subtitle1'>
                                                    Number of medium questions
                                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderColor: 'transparent !important' }}>
                                                <InputBase
                                                    sx={{
                                                        py: 0.7,
                                                        px: 1,
                                                        width: 180,
                                                        borderRadius: 2,
                                                        border: '1px solid #A3A3A3!important'
                                                    }}
                                                    placeholder="Enter Count"
                                                    type='number'
                                                    disabled={isExamEnabled || isLocked ? true : false}
                                                    value={configuration.numberOfMediumQuestions}
                                                    onChange={(e) => handleNumberOfQuestionsChange(e, 'numberOfMediumQuestions')}
                                                />
                                            </TableCell>
                                            <TableCell sx={{
                                                borderColor: 'transparent !important'
                                            }}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography component='span' variant='subtitle1'>
                                                    Number of hard questions
                                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <InputBase
                                                    sx={{
                                                        py: 0.7,
                                                        px: 1,
                                                        width: 180,
                                                        borderRadius: 2,
                                                        border: '1px solid #A3A3A3!important'
                                                    }}
                                                    placeholder="Enter Count"
                                                    type='number'
                                                    disabled={isExamEnabled || isLocked ? true : false}
                                                    value={configuration.numberOfHardQuestions}
                                                    onChange={(e) => handleNumberOfQuestionsChange(e, 'numberOfHardQuestions')}
                                                />
                                                {errors?.numberOfQuestions && (
                                                    <FormHelperText sx={{ color: 'error.main' }}>
                                                        {errors.numberOfQuestions}
                                                    </FormHelperText>
                                                )}
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </>
                                    }

                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Number of Sets
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <InputBase
                                                sx={{
                                                    py: 0.7,
                                                    px: 1,
                                                    width: 180,
                                                    borderRadius: 2,
                                                    border: '1px solid #A3A3A3!important'
                                                }}
                                                placeholder="Enter Count"
                                                type='number'
                                                disabled={isExamEnabled || isLocked ? true : false}
                                                value={configuration.numberOfSets}
                                                onChange={handleNumberOfSetsChange}
                                            />
                                            {errors?.numberOfSets && (
                                                <FormHelperText sx={{ color: 'error.main' }}>
                                                    {errors.numberOfSets}
                                                </FormHelperText>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{
                                            textAlign: 'right',
                                            color: 'primary.main'
                                        }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.numberOfSets} Sets
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Questions Per Set
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <InputBase
                                                sx={{
                                                    py: 0.7,
                                                    px: 1,
                                                    width: 180,
                                                    borderRadius: 2,
                                                    border: '1px solid'
                                                }}
                                                placeholder="Enter Count"
                                                type='number'
                                                value={configuration.questionsPerSet}
                                                disabled={isExamEnabled || contentUploaded || isLocked ? true : false}
                                                onChange={handleQuestionsPerSetChange}
                                            />
                                            {errors?.lowQuestions && (
                                                <FormHelperText sx={{ color: 'error.main' }}>
                                                    {errors.lowQuestions}
                                                </FormHelperText>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{
                                            textAlign: 'right',
                                            color: 'primary.main'
                                        }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.questionsPerSet} Questions
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{ border: 'none' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                Bilingual content
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ border: 'none' }}>
                                            <RadioGroup row name="isBilingual" onChange={handleIsBilingualChange}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled || contentUploaded || isLocked ? true : false}
                                                        checked={configuration.isBilingual === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled || contentUploaded || isLocked ? true : false}
                                                        checked={configuration.isBilingual === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main', border: 'none' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.isBilingual}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ pt: 0 }}>
                                            <Typography component='span' variant='subtitle1'>
                                                How many languages
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ pt: 0 }}>
                                            {configuration.isBilingual === 'No' && (
                                                <FormControl sx={{ width: '100%' }}>
                                                    <InputLabel id="bilingualLable">Languages</InputLabel>
                                                    <Select
                                                        labelId="bilingualLable"
                                                        id="bilingualSingleSelect"
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 320,
                                                            textTransform: 'capitalize',
                                                        }}
                                                        disabled={isExamEnabled || contentUploaded || isLocked ? true : false}
                                                        value={configuration.languages[0] ?? ''}
                                                        onChange={handleBilingualLanguageChange}
                                                        input={
                                                            <OutlinedInput key={'okad'} label='Languages' />
                                                        }
                                                    >
                                                        <MenuItem value=''>None</MenuItem>
                                                        {languages.map((language) => {
                                                            return <MenuItem sx={{
                                                                textTransform: 'capitalize'
                                                            }}
                                                                key={language}
                                                                value={language}
                                                            >
                                                                {language}
                                                            </MenuItem>
                                                        })}
                                                    </Select>
                                                    {languagesError && (
                                                        <FormHelperText sx={{ color: 'error.main' }}>
                                                            Please select atleast one language
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            )}
                                            {configuration.isBilingual === 'Yes' && (
                                                <FormControl sx={{ width: '100%' }}>
                                                    <InputLabel id="bilingualMultiLabel">Languages</InputLabel>
                                                    <Select
                                                        labelId="bilingualMultiLabel"
                                                        id="bilingualMultiSelect"
                                                        multiple
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 320
                                                        }}
                                                        disabled={isExamEnabled || contentUploaded || isLocked ? true : false}
                                                        value={configuration.languages}
                                                        onChange={handleBilingualLanguagesChange}
                                                        input={
                                                            <OutlinedInput id="select-multiple-chip" label='Languages' />
                                                        }
                                                        renderValue={(selected) => <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.75 }}>
                                                            {selected.map((value) => (
                                                                <Box key={value} sx={{
                                                                    display: 'flex',
                                                                    gap: 1.5,
                                                                    bgcolor: 'primary.50',
                                                                    py: 0.5,
                                                                    px: 1.5,
                                                                    borderRadius: 1,
                                                                    textTransform: 'capitalize',
                                                                }}>
                                                                    <span>{value}</span>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        }
                                                    >
                                                        {languages.map((language) => (
                                                            <MenuItem sx={{
                                                                textTransform: 'capitalize'
                                                            }}
                                                                key={language}
                                                                value={language}
                                                            >
                                                                {language}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {languagesError && (
                                                        <FormHelperText sx={{ color: 'error.main' }}>
                                                            Please select atleast one language
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            )}

                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main', pt: 0 }}>
                                            <Typography component='span' variant='subtitle1' sx={{ textTransform: 'capitalize' }}>
                                                {configuration.languages.join(', ')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography component='span' variant='subtitle1'>
                                                Instruction sheets
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <RadioGroup row name="instructionSheet" onChange={handleInstructionSheetChange}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.instructionSheets === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.instructionSheets === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{
                                            textAlign: 'right',
                                            color: 'primary.main',
                                        }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.instructionSheets}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>


                                    <TableRow>
                                        <TableCell sx={{ border: 'none' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                Biometric Required
                                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ border: 'none' }}>
                                            <RadioGroup row name="biometricRequired" onChange={(e, value) => handleRadioChange(value, 'biometricRequired')}>
                                                <FormControlLabel sx={{ width: 150 }} value='Yes' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.biometricRequired === 'Yes'}
                                                    />
                                                } label="Yes" />
                                                <FormControlLabel sx={{ width: 150 }} value='No' control={
                                                    <Radio
                                                        disabled={isExamEnabled ? true : false}
                                                        checked={configuration.biometricRequired === 'No'}
                                                    />
                                                } label="No" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right', color: 'primary.main', border: 'none' }}>
                                            <Typography component='span' variant='subtitle1'>
                                                {configuration.biometricRequired}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Paper>
                </Box>
            </Container>
        </MainContainer >
    );
}


/* <TableRow className='table-row' sx={{ border: 'none !important' }}>
    <TableCell className='table-cell' sx={{ border: 'none' }}>
        <div className='content-title'>
            Upload multiple sets <span className='required'>*</span>
        </div>
    </TableCell>
    <TableCell className='table-cell' sx={{ border: 'none' }}>
        <FormControl  >
            <RadioGroup
                row
                name="row-radio-buttons-group"
            >
                <FormControlLabel sx={{ width: 170 }} value={true} control={<Radio />} label="Yes" />
                <FormControlLabel sx={{ width: 170 }} value={false} control={<Radio />} label="No" />
            </RadioGroup>
        </FormControl>
    </TableCell>
    <TableCell className='table-cell' sx={{ border: 'none', textAlign: 'right' }}>
        <div className='content-end'></div>
    </TableCell>
</TableRow> */
/* <TableRow className='table-row'>
    <TableCell className='table-cell light' sx={{ border: 'none' }}>
        <div className='content-title'>
            How many sets <span className='required'>*</span>
        </div>
    </TableCell>
    <TableCell className='table-cell light' sx={{ border: 'none' }}>
        <Paper
            component="form"
            sx={{
                display: 'flex', alignItems: 'center', width: 180, borderRadius: 2, boxShadow: 'none', border: '1px solid #A3A3A3!important'
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter Count"
                inputProps={{ 'aria-label': 'enter count' }}
            />
            <Divider sx={{ height: 44, borderColor: '#A3A3A3' }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} disableRipple>
                <img src={TRUE_BLACK} alt='' />
            </IconButton>
        </Paper>
    </TableCell>
    <TableCell className='table-cell light' sx={{ border: 'none', textAlign: 'right' }}>
        <div className='content-end'>Sets: </div>
    </TableCell>
</TableRow> */


/* <TableRow className='table-row' sx={{ border: 'none !important' }}>
    <TableCell className='table-cell' sx={{ border: 'none' }}>
        <div className='content-title'>
            Upload bulk content <span className='required'>*</span>
        </div>
    </TableCell>
    <TableCell className='table-cell' sx={{ border: 'none' }}>
        <FormControl  >
            <RadioGroup
                row
                name="row-radio-buttons-group"
            >
                <FormControlLabel sx={{ width: 170 }} value={true} control={<Radio />} label="Yes" />
                <FormControlLabel sx={{ width: 170 }} value={false} control={<Radio />} label="No" />
            </RadioGroup>
        </FormControl>
    </TableCell>
    <TableCell className='table-cell' sx={{ border: 'none', textAlign: 'right' }}>
        <div className='content-end'></div>
    </TableCell>
</TableRow> */
/* <TableRow className='table-row' sx={{ border: 'none!important' }}>
    <TableCell className='table-cell light' sx={{ border: 'none' }}>
        <div className='content-title'>
            How many sets to be created <span className='required'>*</span>
        </div>
    </TableCell>
    <TableCell className='table-cell light' sx={{ border: 'none' }}>
        <Paper
            component="form"
            sx={{
                display: 'flex', alignItems: 'center', width: 180, borderRadius: 2, boxShadow: 'none', border: '1px solid #A3A3A3!important'
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter Count"
            />
            <Divider sx={{ height: 44, borderColor: '#A3A3A3' }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} disableRipple>
                <img src={TRUE_BLACK} alt='' />
            </IconButton>
        </Paper>
    </TableCell>
    <TableCell className='table-cell light' sx={{ border: 'none', textAlign: 'right' }}>
        <div className='content-end'>Sets: </div>
    </TableCell>
</TableRow> */
/* <TableRow className='table-row'>
    <TableCell className='table-cell light' sx={{ border: 'none' }}>
        <div className='content-title'>
            How many questions per set <span className='required'>*</span>
        </div>
    </TableCell>
    <TableCell className='table-cell light' sx={{ border: 'none' }}>
        <Paper
            component="form"
            sx={{
                display: 'flex', alignItems: 'center', width: 180, borderRadius: 2, boxShadow: 'none', border: '1px solid #A3A3A3!important'
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1, borderRadius: 0 }}
                placeholder="Enter Count"
            />
            <Divider sx={{ height: 44, borderColor: '#A3A3A3' }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px', borderRadius: 0 }} disableRipple>
                <img src={TRUE_BLACK} alt='' />
            </IconButton>
        </Paper>
    </TableCell>
    <TableCell className='table-cell light' sx={{ border: 'none', textAlign: 'right' }}>
        <div className='content-end'>Sets: </div>
    </TableCell>
</TableRow> */