import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link, Modal, Autocomplete, TextField } from '@mui/material';

import { getDataWithSets } from '../../../../services/configurationService';
import { useToaster } from '../../../../providers/ToasterProvider';
import { deleteAllBySet, setWiseUpload, getPendingVerificationQuestionNumber } from '../../../../services/questionService';
import { ContentUploadErrorsModal } from '../ContentUploadErrorsModal/ContentUploadErrorsModal'

import RadiusButton from '../../../../components/RadiusButton';

import DELETE from '../../../../assets/images/action-delete.svg';

export const UploadBySet = ({ drive, course, configurationId }) => {

    const params = useParams();
    const projectName = params?.projectName;
    const navigate = useNavigate();
    const { addNotification } = useToaster();

    const [configuration, setConfiguration] = useState(null);
    const [sets, setSets] = useState([]);
    const [fileDetails, setFileDetails] = useState({
        file: null,
        set: null,
        language: null
    });
    const [dataUploaded, setDataUploaded] = useState(false);
    const [errorModal, setErrorModal] = useState({
        isVisible: false,
        errors: []
    });
    const [deleteModal, setDeleteModal] = useState({
        isVisible: false,
        set: null,
        language: null,
    });

    useEffect(() => {
        if (configurationId) {
            const fetchConfiguration = async () => {
                try {
                    const res = await getDataWithSets(configurationId);
                    if (res.code === 'OK') {
                        setConfiguration(res.data.configuration);
                        setSets(
                            res.data.sets.map((set) => {
                                if (set.languages.length > 1) {
                                    set.languages.push({
                                        language: 'all',
                                        count: 0
                                    });
                                }
                                set.languages = set.languages.map((lan) => {
                                    // lan.previewNo = '';
                                    // lan.inputNo = '';
                                    // lan.filteredOptions = Array.from({ length: lan.count + 1 }, (_, i) => i ? i.toString() : '');
                                    return lan
                                })
                                set.hasUploadedData = !!set.languages.find((lan) => lan.count > 0);
                                return set;
                            })
                        )
                    }
                } catch (error) {
                    console.log('There is an error while getting configuration details', error);
                }
            }
            fetchConfiguration();
        }
    }, [configurationId, dataUploaded]);

    const handleFileDrop = (e, set, language) => {
        const inputFile = e.target.files?.[0];
        if (inputFile && inputFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            if (fileDetails?.set && fileDetails?.file && fileDetails?.language) {
                document.getElementById(`file_${fileDetails.set}_${fileDetails.language}`).value = '';
            }
            setFileDetails({
                file: inputFile,
                set: set,
                language: language
            });
        }
    }
    const handleRemoveFile = () => {
        const set = fileDetails.set;
        if (set) {
            document.getElementById(`file_${fileDetails?.set}_${fileDetails?.language}`).value = '';
            setFileDetails({
                file: null,
                set: null
            });
        }
    }
    const handlePreviewQuestion = async (setNumber, language) => {
        if (!language || !setNumber) return;
        try {
            const body = {
                configurationId: configurationId,
                set: setNumber,
                language: language
            }
            const res = await getPendingVerificationQuestionNumber(body);
            if (res.code === 'OK') {
                navigate(`/${projectName}/upload/content-preview`, {
                    state: {
                        configuration: configuration,
                        language: language,
                        setNumber: setNumber,
                        drive: drive,
                        course: course,
                        questionNo: res.data.questionNo,
                    }
                });
            }
        } catch (error) {
            console.log(error);
            addNotification('Unexpected error occured.', 'danger')
        }
        // const set = sets.find((set) => set.set === setNumber);
        // console.log(res);
        // if (set) {
        //     const previewLanguage = set.languages.find((lan) => lan.language === language);
        //     const questionNo = Number(previewLanguage?.previewNo);
        //     if (questionNo && !isNaN(questionNo)) {
        //         navigate(`/${projectName}/upload/content-preview`, {
        //             state: {
        //                 configuration: configuration,
        //                 language: language,
        //                 setNumber: setNumber,
        //                 drive: drive,
        //                 course: course,
        //                 questionNo: questionNo,
        //             }
        //         });
        //     } else {
        //         addNotification('Please select question number.')
        //     }
        // }
    }
    const handleDeleteAll = (set, language) => {
        if (!language || !set || !drive?.id || !course?.id) return;
        setDeleteModal({
            ...deleteModal,
            isVisible: true,
            set: set,
            language: language
        });
    }
    const handleCloseDeleteModal = () => {
        setDeleteModal({
            ...deleteModal,
            isVisible: false,
            set: null,
            language: null
        })
    }
    const handleDeleteAllConfirm = async () => {
        if (!deleteModal.set || !deleteModal.language || !drive?.id || !course?.id) return;
        try {
            const languages = deleteModal.language === 'all' ? configuration.languages : [deleteModal.language];
            const body = {
                driveId: drive.id,
                courseId: course.id,
                set: deleteModal.set,
                languages: languages
            }
            const res = await deleteAllBySet(body);
            if (res.code === 'OK') {
                setSets(
                    sets.map((set) => {
                        if (set.set === deleteModal.set) {
                            if (deleteModal.language === 'all') {
                                set.languages = set.languages.map((lan) => {
                                    lan.count = 0;
                                    return lan
                                })
                            } else {
                                const setLanguageIndex = set.languages.findIndex((lan) => lan.language === deleteModal.language);
                                if (setLanguageIndex > -1) {
                                    set.languages[setLanguageIndex].count = 0;
                                }
                            }
                            set.hasUploadedData = set.languages.find((lan) => lan.count > 0);
                        }
                        return set;
                    })
                );
                addNotification('content deleted successfully.', 'success');
                setDeleteModal({
                    ...deleteModal,
                    isVisible: false,
                    set: null,
                    language: null
                });
            }
        } catch (error) {
            console.log('There is an error while deleting questions', error);
        }
    }
    const handleContentUpload = async () => {
        if (fileDetails?.file && fileDetails?.set && configuration.id) {
            try {
                const body = new FormData();
                body.append('configurationId', configuration.id);
                body.append('set', fileDetails.set);
                body.append('file', fileDetails.file);
                if (fileDetails.language === 'all') {
                    body.append('languages', JSON.stringify(configuration.languages))
                } else {
                    body.append('languages', JSON.stringify([fileDetails.language]))
                }
                const res = await setWiseUpload(body);
                if (res.code === 'E_BAD_REQUEST') {
                    addNotification('Invalid data in file data', 'danger');
                    setErrorModal({
                        ...errorModal,
                        isVisible: true,
                        errors: res.data.errors,
                    })
                } else {
                    setDataUploaded(!dataUploaded);
                    addNotification('Content uploaded successfully.', 'success');
                }
                document.getElementById(`file_${fileDetails.set}_${fileDetails.language}`).value = '';
                setFileDetails({
                    file: null,
                    set: null,
                    language: null
                });
            } catch (error) {
                console.log('There is an error while uploading file set wise', error);
                addNotification('Content does not uploaded.', 'danger');
            }
        }
    }
    // const setPreviewQuestionNo = (value, setNumber, language) => {
    //     if (value && setNumber && language) {
    //         setSets(
    //             sets.map((set) => {
    //                 if (set.set === setNumber) {
    //                     set.languages = set.languages.map((lan) => {
    //                         if (lan.language === language) {
    //                             lan.previewNo = value;
    //                             lan.inputNo = value;
    //                         }
    //                         return lan;
    //                     })
    //                 }
    //                 return set;
    //             })
    //         )
    //     }
    // }
    // const setPreviewQuestionInput = (value, setNumber, language) => {
    //     if (setNumber && language) {
    //         setSets(
    //             sets.map((set) => {
    //                 if (set.set === setNumber) {
    //                     set.languages = set.languages.map((lan) => {
    //                         if (lan.language === language) {
    //                             lan.inputNo = value;
    //                             lan.previewNo = '';
    //                         }
    //                         return lan;
    //                     })
    //                 }
    //                 return set;
    //             })
    //         )
    //     }
    // }

    return (
        <Fragment>
            <TableContainer sx={{
                p: 2,
                maxHeight: 'inherit',
                maxWidth: 'inherit',
                borderRadius: 1
            }}>
                <Table stickyHeader aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align='left'
                                style={{ width: 140 }}
                                sx={{ border: 'none', p: 0 }}
                            ></TableCell>
                            <TableCell
                                align='left'
                                style={{ minWidth: 320 }}
                                sx={{ border: 'none', p: 0 }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sets.map((setDetails, index) => {
                            return <TableRow key={index}>
                                <TableCell sx={{ border: 'none', verticalAlign: 'top' }}>
                                    <Typography component='span' variant='h6' sx={{
                                        fontSize: 18,
                                        fontWeight: 500,
                                        color: 'primary.main'
                                    }}>Set {setDetails.set}</Typography>
                                </TableCell>
                                <TableCell sx={{ border: 'none', py: 1 }}>
                                    {setDetails?.languages?.map((lan, lanIndex) => {
                                        return <Box key={lanIndex} sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            gap: 2,
                                            mb: 1,
                                        }}>
                                            <Typography sx={{
                                                textTransform: 'capitalize',
                                                fontWeight: 500,
                                                fontSize: 16,
                                                py: 1,
                                                width: 140
                                            }}>{lan.language}</Typography>
                                            <Box sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                gap: 1.5,
                                                py: 1.5,
                                                px: 2.5,
                                                backgroundColor: 'grey.100',
                                                borderRadius: 2,
                                            }}>
                                                {((lan.language !== 'all' && lan?.count <= 0) || (lan.language === 'all' && !setDetails.hasUploadedData)) && <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    height: 24,
                                                }}>
                                                    <Link
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            cursor: 'pointer',
                                                        }}
                                                        component='label'
                                                        htmlFor={`file_${setDetails.set}_${lan.language}`}
                                                    >Select file</Link>
                                                    <input
                                                        style={{ display: 'none' }}
                                                        type='file'
                                                        id={`file_${setDetails.set}_${lan.language}`}
                                                        onChange={(e) => handleFileDrop(e, setDetails.set, lan.language)}
                                                        accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                    />
                                                    {
                                                        fileDetails?.set === setDetails.set && fileDetails?.file && fileDetails?.language === lan.language && fileDetails?.file?.name
                                                            ? (
                                                                <>
                                                                    <Box component='span'>{fileDetails.file.name}</Box>
                                                                    <img
                                                                        style={{ cursor: 'pointer' }}
                                                                        src={DELETE}
                                                                        onClick={handleRemoveFile}
                                                                        alt=''
                                                                    />
                                                                </>
                                                            ) : null
                                                    }
                                                </Box>}
                                                {(lan.language !== 'all' && lan.count > 0) && (
                                                    <Typography
                                                        variant='subtitle1'
                                                        component='h6'
                                                        // onClick={() => handleDeleteAll(setDetails.set, lan.language)}
                                                    >Verified ({lan?.verifiedCount} / {configuration?.questionsPerSet})</Typography>
                                                )}
                                                <Box sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    gap: 1,
                                                    alignItems: 'center',
                                                }}>

                                                    {fileDetails?.file && fileDetails?.set === setDetails.set && fileDetails?.language === lan.language && <RadiusButton
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={handleContentUpload}
                                                    >Upload</RadiusButton>}
                                                    {lan?.count > 0 && <>
                                                        {/* <Autocomplete
                                                            value={lan?.previewNo}
                                                            onChange={(event, newValue) => {
                                                                setPreviewQuestionNo(newValue, setDetails.set, lan.language);
                                                            }}
                                                            inputValue={lan?.inputNo}
                                                            onInputChange={(event, newInputValue) => {
                                                                setPreviewQuestionInput(newInputValue, setDetails.set, lan.language);
                                                            }}
                                                            id="lan"
                                                            options={lan.filteredOptions}
                                                            sx={{ width: 180 }}
                                                            renderInput={(params) => <TextField {...params} label="Question No" />}
                                                        /> */}
                                                        <RadiusButton
                                                            variant='contained'
                                                            color='primary'
                                                            onClick={() => handlePreviewQuestion(setDetails.set, lan.language)}
                                                        >Preview</RadiusButton>
                                                    </>}
                                                    {((lan.language !== 'all' && lan?.count > 0) || (lan.language === 'all' && setDetails.hasUploadedData)) && <RadiusButton
                                                        variant='contained'
                                                        color='secondary'
                                                        onClick={() => handleDeleteAll(setDetails.set, lan.language)}
                                                    >Delete All</RadiusButton>}
                                                </Box>

                                            </Box>
                                        </Box>
                                    })}
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={deleteModal.isVisible}
                onClose={handleCloseDeleteModal}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.base',
                    boxShadow: 24,
                    borderRadius: 2,
                    px: 3,
                    py: 6
                }}>
                    <Typography variant="h6" component="h6" sx={{
                        mb: 5,
                        textAlign: 'center'
                    }}>
                        Are you sure you want to delete all the content for
                        <Box component='span' sx={{ color: 'primary.main' }}> Set {deleteModal.set} </Box>
                        in
                        <Box component='span' sx={{ color: 'primary.main', textTransform: 'capitalize' }}> {deleteModal.language} </Box>
                        language?
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        gap: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <RadiusButton
                            variant='outlined'
                            onClick={handleCloseDeleteModal}
                        >
                            No
                        </RadiusButton>
                        <RadiusButton
                            variant='contained'
                            onClick={handleDeleteAllConfirm}
                        >
                            Yes
                        </RadiusButton>
                    </Box>

                </Box>
            </Modal>
            <ContentUploadErrorsModal currentState={errorModal} setCurrentState={setErrorModal}></ContentUploadErrorsModal>
        </Fragment>
    );
}