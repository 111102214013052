import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Pagination } from '@mui/material';
import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';

import { ProjectContext } from '../../../../providers/projectProvider';
import RadiusButton from '../../../../components/RadiusButton';

import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import CONTENT_ICON from '../../../../assets/images/content-white.svg';
import MOCK_EXAM from '../../../../assets/images/mock-exam.svg';
import LIVE_EXAM from '../../../../assets/images/live-exam.svg';


export const CustomerDashboard = () => {
    const navigate = useNavigate();
    const { projectDetails } = useContext(ProjectContext)

    const totalPages = 12;
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    const columns = [
        { id: 'siNo', label: 'SI.NO', minWidth: 80 },
        { id: 'course', label: 'Course', minWidth: 120 },
        { id: 'examDate', label: 'Exam Date', minWidth: 120 },
        { id: 'batchNo', label: 'Batch No', minWidth: 100 },
        { id: 'contentConfig', label: 'Content Configured', minWidth: 120 },
        { id: 'contentUploaded', label: 'Content Uploaded', minWidth: 120 },
        { id: 'bilingualDataUploaded', label: 'Bilingual Data Uploaded', minWidth: 120 },
        { id: 'instructionUploaded', label: 'Instruction Uploaded', minWidth: 120 },
        { id: 'feedbackUploaded', label: 'Feedback Uploaded', minWidth: 120 },
        { id: 'dataLocked', label: 'Data Locked', minWidth: 120 },
    ];
    const rows = [
        {
            course: 'CET',
            examDate: '3 Jun 2024',
            batchNo: '01',
            contentConfig: false,
            contentUploaded: false,
            bilingualDataUploaded: 'MAR | HIN | BEN | kAN | ENG',
            instructionUploaded: true,
            feedbackUploaded: true,
            dataLocked: true,
        }
    ];

    const goToMockExam = () => {
        if (projectDetails) {
            navigate(`/${projectDetails?.projectShortName}/customer/mock-exam`);
        }
    }
    const goToLiveExam = () => {
        if (projectDetails) {
            navigate(`/${projectDetails?.projectShortName}/customer/live-exam`);
        }
    }

    return (
        <MainContainer >
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography sx={{
                        fontWeight: 500,
                        color: 'text.primary'
                    }}>
                        Dashboard
                    </Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div className="search-header-wrapper">
                    <h1 className="title" style={{ marginRight: 'auto' }}>
                        Dashboard
                    </h1>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='contained'
                        size='large'
                        startIcon={<img src={CONTENT_ICON} />}>
                        Content
                    </RadiusButton>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='outlined'
                        size='large'
                        startIcon={<img src={MOCK_EXAM} />}
                        onClick={goToMockExam}
                    >
                        Mock Exam
                    </RadiusButton>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='outlined'
                        size='large'
                        startIcon={<img src={LIVE_EXAM} />}
                        onClick={goToLiveExam}
                    >
                        LIve Exam
                    </RadiusButton>
                </div>
                <div className="search-header-wrapper">
                    <div className='search-wrapper'>
                        <input type='text' className='search-box' placeholder='Search...' />
                        <img className='search-icon' src={SEARCH_ICON} alt='' />
                    </div>
                </div>


                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => {
                                    return (
                                        <TableRow hover key={rowIndex}>
                                            {columns.map((column, index) => {
                                                const value = row[column.id];
                                                switch (column.id) {
                                                    case 'siNo':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {String(rowIndex + 1).padStart(2, '0')}
                                                        </TableCell>)
                                                    case 'course':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {value}
                                                        </TableCell>)
                                                    case 'examDate':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {value}
                                                        </TableCell>)
                                                    case 'batchNo':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {String(value).padStart(2, '0')}
                                                        </TableCell>)
                                                    case 'bilingualDataUploaded':
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {value}
                                                        </TableCell>)
                                                    default:
                                                        return (<TableCell sx={{ textTransform: 'capitalize' }} key={column.id}>
                                                            {value == true ?
                                                                <Box sx={{ color: '#06C270' }}>yes</Box>
                                                                :
                                                                <Box sx={{ color: 'red' }}>no</Box>
                                                            }
                                                        </TableCell>)
                                                }
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination sx={{
                    mt: 'auto',
                }} count={totalPages}
                    page={page}
                    onChange={handleChange}
                    shape="rounded"
                />

            </Container>

        </MainContainer>
    );
}
