import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Typography, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Pagination, Modal } from '@mui/material';

import { useToaster } from '../../../../providers/ToasterProvider';
import { AuthContext } from '../../../../providers/auth';
import { listWithConfigurationStatusByPagination } from '../../../../services/masterService';
import { lockExam } from '../../../../services/configurationService';
import dayjs from '../../../../config/dayjs';

import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton'

import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';

const limit = process.env.REACT_APP_PAGE_LIMIT;
let timer = null;

export const ContentDashboardAdmin = () => {

    const params = useParams();
    const projectName = params?.projectName;
    const navigate = useNavigate();
    const { addNotification } = useToaster();

    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [newSearch, setNewSearch] = useState(false);

    const columns = [
        { id: 'driveName', label: 'Drive Name', minWidth: 120 },
        { id: 'courseName', label: 'Course Name', minWidth: 120 },
        { id: 'batchName', label: 'Batch Name', minWidth: 120 },
        { id: 'examDate', label: 'Exam Date', minWidth: 120 },
        { id: 'contentConfigured', label: 'Content Configured', minWidth: 80 },
        { id: 'contentUploaded', label: 'Content Uploaded', minWidth: 80 },
        { id: 'languages', label: 'Bilingual Data Uploaded', minWidth: 190 },
        { id: 'instructionUploaded', label: 'Instruction Uploaded', minWidth: 100 },
        { id: 'feedbackUploaded', label: 'Feedback Uploaded', minWidth: 100 },
        { id: 'isLocked', label: 'Data Locked', minWidth: 80 },
        { id: 'examEnabled', label: 'Exam Enabled', minWidth: 80 },
    ];

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await listWithConfigurationStatusByPagination(search, currentPage, limit);
                if (res.code === 'OK') {
                    const max = Math.ceil(res.data.count / limit);
                    setMaxPages(max);
                    setRows(
                        res.data.rows.map((pm) => {
                            if (pm.configuration) {
                                let languages = []
                                for (let language of pm.configuration.languages) {
                                    let isUploaded = true
                                    for (let setNumber of Object.keys(pm.configuration.uploaded)) {
                                        if (!pm.configuration.uploaded[setNumber].includes(language)) {
                                            isUploaded = false;
                                        }
                                    }
                                    languages.push({
                                        language: language,
                                        isUploaded: isUploaded,
                                    });
                                }
                                pm.configuration.languages = languages
                            }
                            return pm;
                        })
                    );
                }
            } catch (error) {
                console.log('There is an error while fetching content dashboard data', error);
            }
        }
        fetchData();
    }, [currentPage, newSearch]);

    const handleExamEnable = (pm) => {
        const enabled = pm?.enabledOn && pm?.set;
        if (enabled) {
            addNotification('Exam already enabled.')
        } else {
            navigate(`/${projectName}/enable-exam`, {
                state: {
                    projectMasterId: pm.id,
                    driveName: pm['drive.driveName'],
                    courseName: pm['course.courseName'],
                    batchName: pm['batch.batchName'],
                    examDate: pm['examDate']
                }
            });
        }
    }
    const onSearch = (e) => {
        setSearch(e.target.value);
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setCurrentPage(1);
            setNewSearch(!newSearch);
        }, 400);
    }
    const renderTableCell = (columnId, rowIndex) => {
        const row = rows[rowIndex];
        switch (columnId) {
            case 'driveName':
                return row?.['drive.driveName'];
            case 'courseName':
                return row?.['course.courseName'];
            case 'batchName':
                return row?.['batch.batchName'];
            case 'examDate':
                return row?.examDate ? dayjs(row.examDate).format('DD-MM-YYYY') : null;
            case 'contentConfigured':
                return <Box component='span' sx={{
                    cursor: 'default',
                    color: row?.['configuration']?.['contentConfigured'] ? 'success.main' : 'error.main',
                }}>

                    {row?.['configuration']?.['contentConfigured'] ? 'Yes' : 'No'}
                </Box>
            case 'contentUploaded':
                return <Box component='span' sx={{
                    cursor: 'default',
                    color: row?.['configuration']?.['contentUploaded'] ? 'success.main' : 'error.main'
                }}>
                    {row?.['configuration']?.['contentUploaded'] ? 'Yes' : 'No'}
                </Box>
            case 'languages':
                return <Box component='span' sx={{ cursor: 'default' }}>
                    {
                        row?.['configuration']?.['languages']
                            ? row['configuration']?.['languages'].map((language, index) => {
                                return <Box component='span' sx={{
                                    textTransform: 'capitalize',
                                    color: language.isUploaded ? 'success.main' : 'error.main'
                                }} key={index}>{`${language.language}`}{(index !== row?.['configuration']['languages'].length - 1) ? ', ' : null}</Box>
                            })
                            : null
                    }
                </Box>
            case 'instructionUploaded':
                return <Box component='span' sx={{
                    cursor: 'default',
                    color: row?.['configuration']?.['instructionsEnabled'] && row?.['configuration']?.['instructionsUploaded'] ? 'success.main' : 'error.main'
                }}>
                    {row?.['configuration']?.['instructionsEnabled']
                        ? row?.['configuration']?.['instructionsUploaded'] ? 'Yes' : 'No'
                        : null}
                </Box>;
            case 'feedbackUploaded':
                return <Box component='span' sx={{
                    cursor: 'default',
                    color: row?.['configuration']?.['feedbackEnabled'] && row?.['configuration']?.['feedbackUploaded'] ? 'success.main' : 'error.main'
                }}>
                    {row?.['configuration']?.['feedbackEnabled']
                        ? row?.['configuration']?.['feedbackUploaded'] ? 'Yes' : 'No'
                        : null}
                </Box>
            case 'isLocked':
                return <Box component='span' sx={{
                    cursor: 'default',
                    color: row?.['configuration']?.['isLocked'] ? 'success.main' : 'error.main',
                }}>
                    {row?.['configuration']?.['contentUploaded']
                        ? row?.['configuration']?.['isLocked'] ? 'Yes' : 'No'
                        : null}
                </Box>
            case 'examEnabled':
                return <Box component='span' sx={{
                    color: row?.['enabledBy'] && row?.['set'] ? 'success.main' : 'error.main',
                    cursor: 'pointer',
                }} onClick={() => handleExamEnable(row)}>
                    {row?.['configuration']?.['isLocked']
                        ? row?.['enabledBy'] && row?.['set'] ? 'Yes' : 'No'
                        : null}
                </Box>;
            default:
                return null;
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{
                        fontWeight: 400,
                        textTransform: 'capitalize'
                    }}>{projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Content Dashboard</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <h1 className='title'>Content Dashboard</h1>

                <div className="search-header-wrapper">
                    <Box sx={{ mr: 'auto' }}>
                        <div className='search-wrapper'>
                            <input type='text' className='search-box' value={search} onChange={onSearch} placeholder='Search...' />
                            <img className='search-icon' src={SEARCH_ICON} alt='' />

                        </div>
                    </Box>
                </div>

                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => {
                                        return <TableCell
                                            sx={{
                                                color: 'primary.main',
                                                px: 1.5
                                            }}
                                            key={column.id}
                                            align='center'
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!rows?.length
                                    ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                        <TableCell colSpan={11} sx={{ border: 'none', textAlign: 'center' }}>
                                            No records available
                                        </TableCell>
                                    </TableRow>
                                    : rows.map((row, rowIndex) => {
                                        return (
                                            <TableRow hover role="checkbox" key={rowIndex}>
                                                {columns.map((column) => {
                                                    return <TableCell key={column.id} align='center' sx={{
                                                        px: 1.5,
                                                        py: 2,
                                                    }}>
                                                        {renderTableCell(column.id, rowIndex)}
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination sx={{
                    ml: 'auto',
                    mt: 'auto'
                }} count={maxPages} page={currentPage} onChange={handleChange} shape="rounded" />
            </Container>

        </MainContainer>
    );
}