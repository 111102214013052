import Axios from 'axios';
import { axios } from '../config/axios';
import storage from '../config/storage';

const endPoint = '/project/document';

const getListByPage = (search, page, limit) => {
    return axios.post(`${endPoint}/pagination`, {
        search: search,
        page: page,
        limit: limit
    });
}

const create = (createDto) => {
    return axios.post(`${endPoint}/`, createDto);
}

const getDocumentFileById = (documentId) => {
    const token = storage.getToken();

    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/document-file/${documentId}`, {
        responseType: 'blob',
        headers: {
            authorization: token
        }
    });
}

const deleteById = (documentId) => {
    return axios.delete(`${endPoint}/${documentId}`);
}

export {
    getListByPage,
    create,
    getDocumentFileById,
    deleteById,
}