import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Typography, Switch, TextField, FormControlLabel, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import dayjs from '../../../../config/dayjs';

import { getAll as getAllDrives } from '../../../../services/driveService';
import { getAll as getAllCourses } from '../../../../services/courseService';
import { getAll as getAllBatches } from '../../../../services/batchService';
import { createUser, getById, updateById } from '../../../../services/usersService';
import { getExamHallAndServerId, getExamDates } from '../../../../services/masterService';
import { useToaster } from '../../../../providers/ToasterProvider';

import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton';
import { UserCreationMail } from '../UserCreationMail/UserCreationMail';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import BACK from '../../../../assets/images/back.svg';

import './ItSupportDetails.scss';

export const ItSupportDetails = () => {

    const params = useParams();
    const projectName = params?.projectName;
    const location = useLocation();
    const navigate = useNavigate();
    const { addNotification } = useToaster();

    const [isActive, setIsActive] = useState(true);
    const [driveId, setDriveId] = useState(0);
    const [drives, setDrives] = useState([]);
    const [courseId, setCourseId] = useState(0);
    const [courses, setCourses] = useState([]);
    const [batchId, setBatchId] = useState(0);
    const [batches, setBatches] = useState([]);
    const [examDate, setExamDate] = useState('');
    const [examDates, setExamDates] = useState([]);
    const [examHalls, setExamHalls] = useState([]);
    const [examHallId, setExamHallId] = useState(0);
    const [servers, setServers] = useState([]);
    const [serverId, setServerId] = useState(0);
    const [userDetails, setUserDetails] = useState(null);
    const [mailModal, setMailModal] = useState({
        isOpen: false,
        cancelPressed: false,
    });
    const [editMode, setEditMode] = useState(false);

    const itSupportSchema = z.object({
        firstName: z.string().min(1, { message: 'First name is required.' }),
        lastName: z.string().min(1, { message: 'Last name is required.' }),
        email: z.string().min(1, { message: 'User id is required.' }).email('Please enter valid email address.'),
        password: editMode ? z.string().optional().refine(val => !val || val?.length > 7, {
            message: 'Password must be longer than 8 characters.'
        }) : z.string().min(8, { message: 'Password must be longer than 8 characters.' }),
        drive: z.number().gte(1),
        course: z.number().gte(1),
        batch: z.number().gte(1),
        examDate: z.string().min(1, { message: 'Please select exam date.' }),
        examHall: z.number().gte(1),
        server: z.number().gte(1),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        setValue,
    } = useForm({
        resolver: zodResolver(itSupportSchema),
        // defaultValues: {
        //     firstName: 'itsupport',
        //     lastName: 'user',
        //     email: 'itsupport@test.com',
        //     password: 'test@123'
        // }
    });

    useEffect(() => {
        const fetchRequiredData = async () => {
            try {
                const driveRes = await getAllDrives();
                if (driveRes.code === 'OK') {
                    // if (!driveRes.data.length) {
                    //     addNotification('For adding It support user you need to add drive first, you can add new drive from masters.')
                    // }
                    setDrives([...driveRes.data]);
                }
                const courseRes = await getAllCourses();
                if (courseRes.code === 'OK') {
                    // if (!courseRes.data.length) {
                    //     addNotification('For adding It support user you need to add course first, you can add new course from masters.')
                    // }
                    setCourses([...courseRes.data])
                }
                const batchRes = await getAllBatches();
                if (batchRes.code === 'OK') {
                    // if (!batchRes.data.length) {
                    //     addNotification('For adding It support user you need to add batch first, you can add new batch from masters.')
                    // }
                    setBatches([...batchRes.data])
                }
            } catch (error) {
                console.log('There is an error while fetching required data', error);
            }
        }
        fetchRequiredData();
    }, [location]);

    useEffect(() => {
        const userId = location?.state?.userId;
        if (userId) {
            const fetchUserDetails = async () => {
                try {
                    const res = await getById(userId);
                    if (res.code === 'OK') {
                        const details = res.data;
                        console.log(details)
                        setEditMode(true);
                        setValue('firstName', details.firstName);
                        setValue('lastName', details.lastName);
                        setValue('email', details.email);
                        const formatedDate = `${details.examDate.substring(5, 7)}-${details.examDate.substring(8, 10)}-${details.examDate.substring(0, 4)}`
                        setValue('examDate', formatedDate);
                        setExamDate(formatedDate);
                        setIsActive(details.isActive);
                        setValue('drive', details.driveId);
                        setDriveId(details.driveId);
                        setValue('course', details.courseId);
                        setCourseId(details.courseId);
                        setValue('batch', details.batchId);
                        setBatchId(details.batchId);
                        setUserDetails(details);
                    }
                } catch (error) {
                    console.log('There is an error while getting it support user details', error);
                }
            }
            fetchUserDetails();
        }
    }, [location, setValue]);

    useEffect(() => {
        if (mailModal.cancelPressed) {
            const path = location.pathname.replace('/it-support-details', '');
            navigate(path);
        }
    }, [mailModal, navigate, location]);

    useEffect(() => {
        if (driveId === 0 || courseId === 0 || batchId === 0) {
            setValue('examDate', '');
            setValue('examHall', 0);
            setValue('server', 0);
            setExamDate('')
            setExamHallId(0);
            setServerId(0);
        }
        if (driveId && courseId && batchId) {
            const fetchExamDates = async () => {
                try {
                    const body = { driveId, courseId, batchId };
                    const res = await getExamDates(body);
                    if (res.code === 'OK') {
                        const projectMasters = res.data?.projectMasters;
                        if (!projectMasters.length) {
                            addNotification('Exam date not found for selected drive, course and batch.', 'warning');
                        }
                        setExamDates(() => {
                            return projectMasters.map((pm) => {
                                const date = dayjs(pm.examDate);
                                return date.format('DD-MM-YYYY')
                            });
                        });
                    }
                } catch (error) {
                    console.log('There is an error while fetching exam dates', error);
                }
            }
            fetchExamDates();
        }

        if (driveId && courseId && batchId && examDate) {
            const fetchExamHallAndServerId = async () => {
                try {
                    let formatedDate = `${examDate.substring(3, 5)}-${examDate.substring(0, 2)}-${examDate.substring(6, 10)}`;
                    const body = { driveId, courseId, batchId, examDate: formatedDate }
                    const res = await getExamHallAndServerId(body);
                    if (res.code === 'OK') {
                        const examHalls = res?.data?.examHalls ?? [];
                        // if (!examHalls?.length) {
                        //     addNotification('Exam not found for selected exam date', 'warning');
                        // }
                        setExamHalls([...examHalls]);
                        const servers = res?.data?.servers ?? [];
                        // if (!servers?.length) {
                        //     addNotification('Server not found for selected exam date', 'warning');
                        // }
                        setServers([...servers]);
                        if (userDetails) {
                            const examHallIndex = examHalls.findIndex((eh) => eh.id === userDetails.examHallId);
                            if (examHallIndex === -1) {
                                setExamHallId(0);
                                setValue('examHall', 0);
                            } else {
                                setValue('examHall', userDetails.examHallId);
                                setExamHallId(userDetails.examHallId);
                            }
                            const serverIndex = servers.findIndex((s) => s.id === userDetails.serverId);
                            if (serverIndex === -1) {
                                setValue('server', 0);
                                setServerId(0);
                            } else {
                                setValue('server', userDetails.serverId);
                                setServerId(userDetails.serverId);
                            }
                        }
                    }
                } catch (error) {
                    console.log('There is an error while getting exam hall and server id', error);
                }
            }
            fetchExamHallAndServerId();
        }

    }, [driveId, courseId, batchId, examDate])

    const onGoBack = () => {
        navigate(`/${projectName}/it-support`);
    }
    const submitForm = async (values) => {
        if (!values.drive || !values.course || !values.batch || !values.examDate || !values.server || !values.examHall) {
            return;
        }
        const date = `${examDate.substring(3, 5)}-${examDate.substring(0, 2)}-${examDate.substring(6, 10)}`
        const body = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            driveId: driveId,
            courseId: courseId,
            batchId: batchId,
            examDate: date,
            serverId: serverId,
            examHallId: examHallId,
            isActive: isActive
        }

        if (editMode) {
            try {
                if (!body.password) {
                    delete body.password;
                }
                const res = await updateById(userDetails.id, body);
                if (res.code === 'OK') {
                    addNotification('User details updated successfully', 'success');
                    onGoBack();
                }
            } catch (error) {
                console.log('There is an error while updating it support user', error);
            }
        } else {
            try {
                body.userType = 6;
                const res = await createUser(body);
                if (res.code === 'CREATED') {
                    const data = {
                        ...res.data.user,
                        password: values.password
                    }
                    setUserDetails(data);
                    setMailModal({
                        ...mailModal,
                        isOpen: true,
                    });
                    addNotification('User added successfully', 'success');
                }
            } catch (error) {
                if (error?.response?.data?.data?.code === 'E_DUPLICATE') {
                    addNotification('User email address already exists.', 'danger');
                }
                console.log('There is an error while adding it support user', error);
            }
        }

    }
    const handleActiveChange = () => {
        setIsActive(!isActive)
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{
                        fontWeight: 400,
                        textTransform: 'capitalize'
                    }}>{projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>
                        IT Support
                    </Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>

                <div className="search-header-wrapper" style={{ marginBottom: '24px' }}>
                    <Typography component='h5' variant='h5' sx={{
                        mr: 'auto',
                    }}>
                        {editMode ? 'Edit' : 'Create'} IT Support User
                    </Typography>
                </div>

                <Box sx={{
                    bgcolor: 'background.base',
                    borderRadius: 6,
                    p: 4
                }} className='create-user-form-wrapper'>
                    <Box component='form' onSubmit={handleSubmit(submitForm)}>
                        <div className="user-form-details">

                            <TextField
                                id='firstName'
                                name='firstName'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>First Name</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('firstName')}
                                error={Boolean(errors.firstName)}
                                helperText={errors.firstName?.message}
                                onBlur={() => trigger('firstName')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                            />

                            <TextField
                                id='lastName'
                                name='lastName'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>Last Name</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('lastName')}
                                error={Boolean(errors.lastName)}
                                helperText={errors.lastName?.message}
                                onBlur={() => trigger('lastName')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                            />

                            <TextField
                                id='email'
                                name='email'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>Email</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('email')}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                                onBlur={() => trigger('email')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                                disabled={editMode ? true : false}
                            />

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <TextField
                                    id='password'
                                    name='password'
                                    className='form-field'
                                    label={
                                        <>
                                            <Box component='span'>Password</Box>
                                            <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                        </>
                                    }
                                    type='password'
                                    variant='outlined'
                                    {...register('password')}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password?.message}
                                    onBlur={() => trigger('password')}
                                    InputLabelProps={editMode ? { shrink: true } : {}}
                                />
                                {editMode && <FormHelperText sx={{
                                    mt: '-10px',
                                    color: 'primary.main',
                                    mb: 1.5
                                }}>
                                    Enter password only if you wish to update.
                                </FormHelperText>}
                            </Box>

                            <FormControl className='form-field' sx={{
                                maxWidth: '100%',
                                flexGrow: 1
                            }}>
                                <InputLabel id="driveNameLabel">
                                    Drive Name
                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                </InputLabel>
                                <Select
                                    labelId="driveNameLabel"
                                    id="drive"
                                    label='Exam Hall Name*'
                                    margin='none'
                                    name='drive'
                                    error={Boolean(errors.drive)}
                                    onBlur={() => trigger('drive')}
                                    value={driveId}
                                    onChange={(e, event) => {
                                        setDriveId(event.props.value);
                                        setValue('drive', event.props.value);
                                    }}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    {
                                        drives.map(
                                            (drive) => <MenuItem key={drive.id} value={drive.id}>
                                                {drive.driveName}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                {errors.drive?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please select drive name
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl className='form-field' sx={{
                                maxWidth: '100%',
                                flexGrow: 1
                            }}>
                                <InputLabel id="courseNameLabel">
                                    Course Name
                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                </InputLabel>
                                <Select
                                    labelId="courseNameLabel"
                                    id="course"
                                    label='Course Name*'
                                    margin='none'
                                    name='course'
                                    error={Boolean(errors.course)}
                                    onBlur={() => trigger('course')}
                                    value={courseId}
                                    onChange={(e, event) => {
                                        setCourseId(event.props.value);
                                        setValue('course', event.props.value);
                                    }}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    {
                                        courses.map(
                                            (course) => <MenuItem key={course.id} value={course.id}>
                                                {course.courseName}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                {errors.course?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please select course name
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl className='form-field' sx={{
                                maxWidth: '100%',
                                flexGrow: 1
                            }}>
                                <InputLabel id="batchNameLabel">Batch Name*</InputLabel>
                                <Select
                                    labelId="batchNameLabel"
                                    id="batch"
                                    label='Batch Name*'
                                    margin='none'
                                    name='batch'
                                    error={Boolean(errors.batch)}
                                    onBlur={() => trigger('batch')}
                                    value={batchId}
                                    onChange={(e, event) => {
                                        setBatchId(event.props.value);
                                        setValue('batch', event.props.value);
                                    }}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    {
                                        batches.map(
                                            (batch) => <MenuItem key={batch.id} value={batch.id}>
                                                {batch.batchName}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                {errors.batch?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please select batch name
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl className='form-field' sx={{
                                maxWidth: '100%',
                                flexGrow: 1
                            }}>
                                <InputLabel id="examDateLabel">Exam Date*</InputLabel>
                                <Select
                                    labelId="examDateLabel"
                                    id="examDate"
                                    label='Exam Date*'
                                    margin='none'
                                    name='examDate'
                                    error={Boolean(errors.examDate)}
                                    onBlur={() => trigger('examDate')}
                                    value={examDate}
                                    onChange={(e, event) => {
                                        setExamDate(event.props.value);
                                        setValue('examDate', event.props.value);
                                    }}
                                >
                                    <MenuItem value=''>None</MenuItem>
                                    {
                                        examDates.map(
                                            (examDate) => <MenuItem key={examDate} value={examDate}>
                                                {examDate}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                {errors.examDate?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please select exam date
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {/* <DatePicker label='Date*' value={examDate} onChange={(date) => setExamDate(date)} sx={{
                                flexGrow: 1,
                                maxWidth: '100%'
                            }} /> */}

                            <FormControl className='form-field' sx={{
                                maxWidth: '100%',
                                flexGrow: 1
                            }}>
                                <InputLabel id="examHallLabel">
                                    Exam Hall Name
                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                </InputLabel>
                                <Select
                                    labelId="examHallLabel"
                                    id="examHall"
                                    label='Exam Hall Name*'
                                    margin='none'
                                    name='examHall'
                                    error={Boolean(errors.examHall)}
                                    onBlur={() => trigger('examHall')}
                                    value={examHallId}
                                    onChange={(e, event) => {
                                        setExamHallId(event.props.value);
                                        setValue('examHall', event.props.value);
                                    }}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    {
                                        examHalls.map(
                                            (examHall) => <MenuItem key={examHall.id} value={examHall.id}>
                                                {examHall.hallName}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                {errors.examHall?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please select exam hall name
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl className='form-field' sx={{
                                maxWidth: '100%',
                                flexGrow: 1
                            }}>
                                <InputLabel id="serverIdLabel">
                                    Server Id
                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                </InputLabel>
                                <Select
                                    labelId="serverIdLabel"
                                    id="server"
                                    label='Server Id*'
                                    margin='none'
                                    name='server'
                                    error={Boolean(errors.server)}
                                    onBlur={() => trigger('server')}
                                    value={serverId}
                                    onChange={(e, event) => {
                                        setServerId(event.props.value);
                                        setValue('server', event.props.value);
                                    }}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    {
                                        servers.map(
                                            (server) => <MenuItem key={server.id} value={server.id}>
                                                {server.serverId}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                {errors.server?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please select server id
                                    </FormHelperText>
                                )}
                            </FormControl>

                        </div>

                        <FormControlLabel sx={{
                            mb: 3
                        }} control={
                            <Switch
                                checked={isActive}
                                onChange={handleActiveChange}
                            />
                        } label={<Box component='span'>Active</Box>} />

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <RadiusButton sx={{
                                textTransform: 'uppercase',
                                fontWeight: 500,
                            }} color='grey'
                                variant='contained'
                                size='large'
                                onClick={onGoBack}
                                startIcon={<img src={BACK} alt='' />}>
                                Back
                            </RadiusButton>
                            <RadiusButton sx={{
                                textTransform: 'uppercase',
                                fontWeight: 500,
                            }} color='primary'
                                type="submit"
                                variant='contained'
                                size='large'>
                                Submit
                            </RadiusButton>
                        </Box>

                    </Box>
                </Box>

            </Container>
            <UserCreationMail userDetails={userDetails} openState={mailModal.isOpen} setMailModal={setMailModal} />
        </MainContainer >
    );
}
