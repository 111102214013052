import Axios from "axios";
import { axios } from "../config/axios";
import storage from "../config/storage";

const endPoint = '/project/user';

const createUser = async (body) => {
    return axios.post(`${endPoint}`, body);
}

const getById = async (userId) => {
    return axios.get(`${endPoint}/${userId}`);
}

const getListByPageAndType = (userType, search, page, limit) => {
    return axios.post(`${endPoint}/pagination`, {
        userType: userType,
        search: search,
        page: page,
        limit: limit,
    });
}

const sendWelcomeMail = (body) => {
    return axios.post(`${endPoint}/send-welcome-mail`, body);
}

const updateById = async (userId, body) => {
    return axios.put(`${endPoint}/${userId}`, body);
}

const deleteById = async (userId) => {
    return axios.delete(`${endPoint}/${userId}`);
}

const downloadUserTemplate = async () => {
    const token = storage.getToken();

    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-user-template`, {
        responseType: 'blob',
        headers: {
            Authorization: token
        }
    });
}

const createBulkUsers = async (body) => {
    return axios.post(`${endPoint}/create-bulk-users`, body);
}

export {
    createUser,
    getById,
    getListByPageAndType,
    sendWelcomeMail,
    updateById,
    deleteById,
    downloadUserTemplate,
    createBulkUsers,
}