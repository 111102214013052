import { Navigate } from "react-router-dom";

import {
    AdminLayout,
    AdminProjects,
    Dashboard,
    Users,
    ItSupport,
    ItSupportDetails,
    Biometric,
    BiometricDetails,
    UserDetails,
    ContentDetails,
    Masters,
    MasterDetails,
    Login,
    Content,
    ContentDashboard,
    ContentConfiguration,
    ContentUpload,
    FeedbackUpload,
    ContentPreview,
    EnableExam,
    ScoreManagement,
    CustomerDashboard,
    CustomerMockExam,
    CustomerLiveExam,
    CustomerChart,
    UploadLayout,
    FeedbackPreview,
    ContentDashboardLayout,
    Documents,
} from "../Modules/Admin";
// import { Login } from "../Modules/Shared";
import AdminAuthGuard from './AdminAuthGuard';
import ContentAdminGuard from './ContentAdminGuard';
// import AdminProjectGuard from "./AdminProjectGuard";


export const AdminRoutes = [
    // {
    //     path: '/project',
    //     element: <Navigate to='/project/project' />
    // },
    {
        path: ':projectName',
        element: <AdminLayout />,
        children: [
            {
                path: '',
                element: <AdminProjects />,
            },
            {
                path: 'login',
                element: <Login />
            },
            {
                path: 'dashboard',
                element: <AdminAuthGuard component={<Dashboard />} />
            },
            {
                path: 'masters',
                element: <AdminAuthGuard component={<Masters />} />
            },
            {
                path: 'documents',
                element: <AdminAuthGuard component={<Documents />} />
            },
            {
                path: 'masters/master-details',
                element: <AdminAuthGuard component={<MasterDetails />} />
            },
            {
                path: 'users',
                element: <Users />
            },
            {
                path: 'users/user-details',
                element: <UserDetails />
            },
            {
                path: 'content',
                element: <Content />
            },
            {
                path: 'content/content-details',
                element: <ContentDetails />
            },
            {
                path: 'it-support',
                element: <ItSupport />
            },
            {
                path: 'it-support/it-support-details',
                element: <ItSupportDetails />
            },
            {
                path: 'biometric',
                element: <Biometric />
            },
            {
                path: 'biometric/biometric-details',
                element: <BiometricDetails />
            },
            {
                path: 'content-dashboard',
                element: <ContentDashboardLayout />
            },
            {
                path: 'content-configuration',
                element: <ContentConfiguration />
            },
            {
                path: 'upload',
                children: [
                    {
                        path: '',
                        element: <UploadLayout />
                    },
                    {
                        path: 'content-preview',
                        element: <ContentPreview />
                    },
                    {
                        path: 'feedback-preview',
                        element: <FeedbackPreview />
                    },
                ],
            },
            {
                path: 'enable-exam',
                element: <ContentAdminGuard component={<EnableExam />} />
            },
            {
                path: 'score-management',
                element: <ScoreManagement />
            },
            {
                path: 'customer',
                children: [
                    {
                        path: '',
                        element: <Navigate to='dashboard' />
                    },
                    {
                        path: 'dashboard',
                        element: <CustomerDashboard />
                    },
                    {
                        path: 'chart',
                        element: <CustomerChart />
                    },
                    {
                        path: 'mock-exam',
                        element: <CustomerMockExam />
                    },
                    {
                        path: 'live-exam',
                        element: <CustomerLiveExam />
                    }
                ]
            },
        ]
    }
];
