import { Breadcrumbs, Typography, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import RadiusButton from '../../../../components/RadiusButton';
import ATTENDANCE_DATA from '../../../../assets/images/attendance-data.svg';
import QUESTION_TEMPLATE from '../../../../assets/images/question.svg';
import ANSWER_KEY from '../../../../assets/images/answer-key.svg';
import RAW_SCORES from '../../../../assets/images/raw-scores.svg';
import REPORT from '../../../../assets/images/report.svg';
import DOWNLOAD from '../../../../assets/images/download.svg';
import UPLOAD from '../../../../assets/images/upload-green.svg';
import GENERATE from '../../../../assets/images/generate.svg';

import { DatePicker } from '@mui/x-date-pickers';


export const ScoreManagement = () => {

    return (
        <MainContainer>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400 }}>Project Name</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>
                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>
                        Score Management
                    </Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography component='h5' variant='h5' sx={{
                        mb: 3
                    }}>
                        Score Management
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        marginBottom: 3,
                        columnGap: 2,
                        rowGap: 1
                    }}>
                        <FormControl sx={{ width: 238, bgcolor: 'background.default' }}>
                            <InputLabel>Course Name</InputLabel>
                            <Select
                                value={0}
                                label="Course Name"
                            >
                                <MenuItem value={0}>None</MenuItem>
                            </Select>
                        </FormControl>
                        <DatePicker label='Date' sx={{
                            bgcolor: 'background.default'
                        }} />
                        <FormControl sx={{
                            width: 238,
                            bgcolor: 'background.default',
                            mr: 'auto'
                        }}>
                            <InputLabel>Batch Name</InputLabel>
                            <Select
                                value={0}
                                label="Batch Name"
                            >
                                <MenuItem value={0}>None</MenuItem>
                            </Select>
                        </FormControl>

                        <RadiusButton sx={{
                            textTransform: 'uppercase',
                            fontWeight: 500,
                        }} color='primary'
                            variant='contained'
                            size='large'
                        >
                            select
                        </RadiusButton>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'background.base',
                        p: 3
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            gap: 2,
                            mb: 3,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: 1,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 130,
                                width: '30%',
                                maxWidth: 305,
                                p: 3,
                                textAlign: 'center',
                            }}>
                                <Typography sx={{
                                    color: 'error.main',
                                    fontWeight: 500,
                                }}>
                                    23, 333
                                </Typography>
                                <Typography sx={{
                                    fontWeight: 500,
                                }}>
                                    Candidate Schedule
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: 1,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 130,
                                width: '30%',
                                maxWidth: 305,
                                p: 3,
                                textAlign: 'center',
                            }}>
                                <Typography sx={{
                                    color: 'error.main',
                                    fontWeight: 500,
                                }}>
                                    23, 333
                                </Typography>
                                <Typography sx={{
                                    fontWeight: 500,
                                }}>
                                    Candidate Present
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: 1,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 130,
                                width: '30%',
                                maxWidth: 305,
                                p: 3,
                                textAlign: 'center',
                            }}>
                                <Typography sx={{
                                    color: 'error.main',
                                    fontWeight: 500,
                                }}>
                                    23, 333
                                </Typography>
                                <Typography sx={{
                                    fontWeight: 500,
                                }}>
                                    Candidate Absent
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            maxWidth: 1040,
                            margin: '0 auto',
                            columnGap: 4,
                            rowGap: 4,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={ATTENDANCE_DATA} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    attendance data
                                </Box>
                                <Box sx={{
                                    display: 'inline-flex',
                                    gap: 1,
                                }} >
                                    <img src={DOWNLOAD} alt="" />
                                    <Box sx={{
                                        color: 'success.main',
                                        fontSize: 12,
                                        textDecorationLine: 'underline',
                                        cursor: 'pointer'
                                    }}>
                                        Download Now
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={QUESTION_TEMPLATE} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    question template
                                </Box>
                                <Box sx={{
                                    display: 'inline-flex',
                                    gap: 1,
                                }} >
                                    <img src={DOWNLOAD} alt="" />
                                    <Box sx={{
                                        color: 'success.main',
                                        fontSize: 12,
                                        textDecorationLine: 'underline',
                                        cursor: 'pointer'
                                    }}>
                                        Download Now
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={ANSWER_KEY} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    answer key template
                                </Box>
                                <Box sx={{
                                    display: 'inline-flex',
                                    gap: 1,
                                }} >
                                    <img src={UPLOAD} alt="" />
                                    <Box sx={{
                                        color: 'success.main',
                                        fontSize: 12,
                                        textDecorationLine: 'underline',
                                        cursor: 'pointer'
                                    }}>
                                        Upload Now
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={RAW_SCORES} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    raw scores
                                </Box>
                                <Box sx={{
                                    display: 'inline-flex',
                                    gap: 1,
                                }} >
                                    <img src={GENERATE} alt="" />
                                    <Box sx={{
                                        color: 'success.main',
                                        fontSize: 12,
                                        textDecorationLine: 'underline',
                                        cursor: 'pointer'
                                    }}>
                                        Generate Now
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={RAW_SCORES} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    raw scores
                                </Box>
                                <Box sx={{
                                    display: 'inline-flex',
                                    gap: 1,
                                }} >
                                    <img src={DOWNLOAD} alt="" />
                                    <Box sx={{
                                        color: 'success.main',
                                        fontSize: 12,
                                        textDecorationLine: 'underline',
                                        cursor: 'pointer'
                                    }}>
                                        Download Now
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={REPORT} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    10X report
                                </Box>
                                <Box sx={{
                                    display: 'inline-flex',
                                    gap: 1,
                                }} >
                                    <img src={GENERATE} alt="" />
                                    <Box sx={{
                                        color: 'success.main',
                                        fontSize: 12,
                                        textDecorationLine: 'underline',
                                        cursor: 'pointer'
                                    }}>
                                        Generate Now
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                </Box>


            </Container>

        </MainContainer>
    );
}
