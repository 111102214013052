import Axios from 'axios';
// import { axios } from '../config/axios';
import storage from '../config/storage';

const endPoint = '/project/candidate';

const downloadCandidateTemplate = () => {
    const token = storage.getToken();

    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-candidate-template`, {
        responseType: 'blob',
        headers: {
            authorization: token
        }
    });
}

export {
    downloadCandidateTemplate,
}