import { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Typography, Table, TableContainer, Paper, FormControlLabel, TableHead, TableRow, TableCell, TableBody, Pagination, Switch, FormGroup, Modal } from '@mui/material';
import { Container, MainContainer } from '../../../../components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from '../Menu/Menu';

import { useToaster } from '../../../../providers/ToasterProvider';
import { getListByPageAndType, updateById, deleteById } from '../../../../services/usersService';
import moduleTypes from '../../../../constant/moduleTypes';
import RadiusButton from '../../../../components/RadiusButton';

import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import IT_SUPPORT from '../../../../assets/images/it-support.svg';
import ADD_CONTENT from '../../../../assets/images/content.svg';
import ADD_BIOMETRIC from '../../../../assets/images/biometric.svg';
import ADD_USER_WHITE from '../../../../assets/images/add-user-white.svg';
import PENCIL from '../../../../assets/images/pencil.svg';
import ACTION_DELETE from '../../../../assets/images/action-delete.svg';

import './Users.scss';

const columns = [
    { id: 'name', label: 'Name', minWidth: 140 },
    { id: 'email', label: 'Email', minWidth: 220 },
    { id: 'mobile', label: 'Contact No', minWidth: 140 },
    { id: 'assignedModules', label: 'Modules', minWidth: 200 },
    { id: 'actions', label: 'Actions', minWidth: 190 },
    { id: 'status', label: '', minWidth: 140 },
];

const limit = process.env.REACT_APP_PAGE_LIMIT;
let timer = null;

export const Users = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [deleteModalToggler, setDeleteModalToggler] = useState(false);
    const { addNotification } = useToaster();
    const [search, setSearch] = useState('');
    const [newSearch, setNewSearch] = useState(false);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await getListByPageAndType(3, search, currentPage, limit)
                if (res.code === 'OK') {
                    const maxPageNumber = Math.ceil(res.data.count / limit);
                    setMaxPages(maxPageNumber);
                    setUsers(
                        res.data.rows.map((user) => {
                            if (user.modules.length) {
                                const hasModules = user.modules.map((m) => +m);
                                user.assignedModules = [];
                                for (let module of moduleTypes) {
                                    if (hasModules.includes(module.code)) {
                                        user.assignedModules.push(module.displayKey);
                                    }
                                }
                            }
                            user.name = `${user.firstName} ${user.lastName}`;
                            return user;
                        })
                    );
                }
            } catch (error) {
                console.log('There is an error while fetching users', error);
            }
        }
        fetchUsers();
    }, [currentPage, newSearch])

    const goToItSupport = () => {
        const path = location.pathname.replace('users', 'it-support');
        navigate(path);
    }
    const goToBiometric = () => {
        const path = location.pathname.replace('users', 'biometric');
        navigate(path);
    }
    const goToContent = () => {
        const path = location.pathname.replace('users', 'content');
        navigate(path);
    }
    const goToCreateUser = () => {
        const path = `${location.pathname}/user-details`
        navigate(path);
    }

    const onActiveToggle = async (userIndex, value) => {
        const user = users[userIndex];
        try {
            const res = await updateById(user.id, { isActive: value });
            if (res.code === 'OK') {
                setUsers(
                    users.map((user, index) => {
                        if (userIndex === index) {
                            user.isActive = value;
                        }
                        return user;
                    })
                );
            }
        } catch (error) {
            console.log('There is an error while updating user', error);
        }
    }

    const handleDeleteUser = (userIndex) => {
        const user = users[userIndex];
        if (user?.id) {
            setUserId(user.id);
            setDeleteModalToggler(true);
        }
    }

    const onConfirmDelete = async () => {
        try {
            const res = await deleteById(userId);
            if (res.code === 'OK') {
                setDeleteModalToggler(false);
                setUsers(users.filter((u) => u.id !== userId));
                setUserId(null);
                addNotification('User has been deleted successfully.', 'success');
            }
        } catch (error) {
            console.log('There is an error while deleting biometric user', error);
        }
    }
    const onSearch = (e) => {
        setSearch(e.target.value);
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setCurrentPage(1);
            setNewSearch(!newSearch);
        }, 400);
    }

    const renderTableCell = (columnId, rowIndex) => {
        const row = users[rowIndex];
        switch (columnId) {
            case 'actions':
                return (<>
                    <Link to={'user-details'} state={{ userId: row.id }}>
                        <img src={PENCIL} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'success.main'
                        }}>Edit</Typography>
                    </Link>
                    <Box component='span' sx={{
                        mx: 1,
                        cursor: 'pointer'
                    }} onClick={() => handleDeleteUser(rowIndex)}>
                        <img src={ACTION_DELETE} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'error.main'
                        }}>Delete</Typography>
                    </Box>
                </>
                );
            case 'status':
                return (
                    <FormGroup >
                        <FormControlLabel control={<Switch checked={row['isActive']} onChange={(e, value) => onActiveToggle(rowIndex, value)} />} label="Active" />
                    </FormGroup>
                );
            case 'assignedModules':
                return row['assignedModules'].join(', ');
            default:
                return row[columnId];
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400 }}>View Project</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>User List</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <h1 className='title'>
                    User List
                </h1>

                <div className="search-header-wrapper">
                    <div className='search-wrapper'>
                        <input type='text' className='search-box' value={search} onChange={onSearch} placeholder='Search...' />
                        <img className='search-icon' src={SEARCH_ICON} alt='' />
                    </div>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary"
                        type="submit"
                        variant='contained'
                        startIcon={<img src={ADD_CONTENT} alt='' />}
                        onClick={goToContent}
                        size='large'
                    >
                        View Content
                    </RadiusButton>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary"
                        type="submit"
                        variant='contained'
                        startIcon={<img src={IT_SUPPORT} alt='' />}
                        onClick={goToItSupport}
                        size='large'>
                        View It Support
                    </RadiusButton>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary"
                        type="submit"
                        variant='contained'
                        startIcon={<img src={ADD_BIOMETRIC} alt='' />}
                        onClick={goToBiometric}
                        size='large'
                    >
                        View Biometric
                    </RadiusButton>
                </div>

                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                            key={column.id}
                                            align='center'
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!users?.length
                                    ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                        <TableCell colSpan={6} sx={{ border: 'none', textAlign: 'center' }}>
                                            No records available
                                        </TableCell>
                                    </TableRow>
                                    : users.map((row, rowIndex) => {
                                        return (
                                            <TableRow hover key={rowIndex}>
                                                {columns.map((column, index) => {
                                                    return <TableCell key={column.id} align='center' sx={{
                                                        px: 1.5,
                                                        py: 0.75,
                                                        wordBreak: 'break-all'
                                                    }}>
                                                        {renderTableCell(column.id, rowIndex)}
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 2.5,
                    mt: 'auto'
                }}>
                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="primary"
                        type="submit"
                        variant='contained'
                        startIcon={<img src={ADD_USER_WHITE} alt='' />}
                        onClick={goToCreateUser}
                        size='large'
                    >
                        Add user
                    </RadiusButton>
                    <Pagination count={maxPages} page={currentPage} onChange={handlePageChange} sx={{
                        mt: 'auto',
                        ml: 'auto'
                    }} shape="rounded" />
                </Box>

                <Modal open={deleteModalToggler} onClose={() => setDeleteModalToggler(false)}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.base',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 9,
                    }}>
                        <Typography variant='h6' sx={{
                            fontWeight: 400,
                            mb: 8
                        }}>
                            Do you want to delete this user?
                        </Typography>

                        <div className='actions-container' style={{ justifyContent: 'center', gap: '40px' }}>
                            <RadiusButton variant='outlined' onClick={() => setDeleteModalToggler(false)}>
                                No
                            </RadiusButton>
                            <RadiusButton variant='contained' onClick={() => onConfirmDelete()}>
                                Yes
                            </RadiusButton>
                        </div>
                    </Box>
                </Modal>

            </Container>
        </MainContainer >
    );
}
